@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1739365795223/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1739365795223/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1739365795223/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-adult:before { content: "\EA02" }
.icon-alert:before { content: "\EA03" }
.icon-arrow-left:before { content: "\EA04" }
.icon-arrow-lg:before { content: "\EA05" }
.icon-arrow-right-2:before { content: "\EA06" }
.icon-arrow-right:before { content: "\EA07" }
.icon-bed:before { content: "\EA08" }
.icon-bike:before { content: "\EA09" }
.icon-blog:before { content: "\EA0A" }
.icon-boardercross:before { content: "\EA0B" }
.icon-business:before { content: "\EA0C" }
.icon-cable-car:before { content: "\EA0D" }
.icon-calendar:before { content: "\EA0E" }
.icon-camera:before { content: "\EA0F" }
.icon-cart:before { content: "\EA10" }
.icon-chairlift-2:before { content: "\EA11" }
.icon-chairlift-4:before { content: "\EA12" }
.icon-chairlift-6:before { content: "\EA13" }
.icon-check:before { content: "\EA14" }
.icon-children:before { content: "\EA15" }
.icon-close:before { content: "\EA16" }
.icon-cross:before { content: "\EA17" }
.icon-crosscountry:before { content: "\EA18" }
.icon-date:before { content: "\EA19" }
.icon-delete-forever:before { content: "\EA1A" }
.icon-download:before { content: "\EA1B" }
.icon-drop:before { content: "\EA1C" }
.icon-dropdown:before { content: "\EA1D" }
.icon-edit:before { content: "\EA1E" }
.icon-elements:before { content: "\EA1F" }
.icon-facebook:before { content: "\EA20" }
.icon-facilities:before { content: "\EA21" }
.icon-family-additional-member:before { content: "\EA22" }
.icon-family-restroom:before { content: "\EA23" }
.icon-family:before { content: "\EA24" }
.icon-filter:before { content: "\EA25" }
.icon-freeridestrecke:before { content: "\EA26" }
.icon-funpark-jatz:before { content: "\EA27" }
.icon-giftcard:before { content: "\EA28" }
.icon-golf-course:before { content: "\EA29" }
.icon-golf:before { content: "\EA2A" }
.icon-gondola-lift-6:before { content: "\EA2B" }
.icon-gondola-lift:before { content: "\EA2C" }
.icon-grandma:before { content: "\EA2D" }
.icon-grandpa:before { content: "\EA2E" }
.icon-health-safty:before { content: "\EA2F" }
.icon-height-railway:before { content: "\EA30" }
.icon-hike:before { content: "\EA31" }
.icon-hockey:before { content: "\EA32" }
.icon-hotel:before { content: "\EA33" }
.icon-info:before { content: "\EA34" }
.icon-instagram:before { content: "\EA35" }
.icon-kidspark:before { content: "\EA36" }
.icon-king-bed:before { content: "\EA37" }
.icon-landscape:before { content: "\EA38" }
.icon-language:before { content: "\EA39" }
.icon-last-minute:before { content: "\EA3A" }
.icon-lawinen:before { content: "\EA3B" }
.icon-like:before { content: "\EA3C" }
.icon-linkedin:before { content: "\EA3D" }
.icon-mail:before { content: "\EA3E" }
.icon-map:before { content: "\EA3F" }
.icon-masks:before { content: "\EA40" }
.icon-merchandise:before { content: "\EA41" }
.icon-minus:before { content: "\EA42" }
.icon-mountains:before { content: "\EA43" }
.icon-nature-people:before { content: "\EA44" }
.icon-news:before { content: "\EA45" }
.icon-night-shelter:before { content: "\EA46" }
.icon-open:before { content: "\EA47" }
.icon-pause:before { content: "\EA48" }
.icon-personalisierung:before { content: "\EA49" }
.icon-pinterest:before { content: "\EA4A" }
.icon-pipe:before { content: "\EA4B" }
.icon-play:before { content: "\EA4C" }
.icon-plus:before { content: "\EA4D" }
.icon-poi:before { content: "\EA4E" }
.icon-profile:before { content: "\EA4F" }
.icon-quiz:before { content: "\EA50" }
.icon-quote:before { content: "\EA51" }
.icon-rain-probability:before { content: "\EA52" }
.icon-rates:before { content: "\EA53" }
.icon-remove:before { content: "\EA54" }
.icon-restaurant:before { content: "\EA55" }
.icon-school:before { content: "\EA56" }
.icon-scroll-down:before { content: "\EA57" }
.icon-search:before { content: "\EA58" }
.icon-seminar:before { content: "\EA59" }
.icon-shopping_cart:before { content: "\EA5A" }
.icon-ski-freeride:before { content: "\EA5B" }
.icon-sledging:before { content: "\EA5C" }
.icon-slopes:before { content: "\EA5D" }
.icon-snow:before { content: "\EA5E" }
.icon-snowboard:before { content: "\EA5F" }
.icon-snowflake:before { content: "\EA60" }
.icon-snowshoe:before { content: "\EA61" }
.icon-snowshoeing:before { content: "\EA62" }
.icon-spa:before { content: "\EA63" }
.icon-speedstrecke:before { content: "\EA64" }
.icon-t-bar-lift:before { content: "\EA65" }
.icon-teen:before { content: "\EA66" }
.icon-teenager:before { content: "\EA67" }
.icon-tel:before { content: "\EA68" }
.icon-tickets:before { content: "\EA69" }
.icon-tiktok:before { content: "\EA6A" }
.icon-trailrunning:before { content: "\EA6B" }
.icon-tripadvisor:before { content: "\EA6C" }
.icon-twitter:before { content: "\EA6D" }
.icon-upload:before { content: "\EA6E" }
.icon-weather-1:before { content: "\EA6F" }
.icon-weather-10:before { content: "\EA70" }
.icon-weather-11:before { content: "\EA71" }
.icon-weather-12:before { content: "\EA72" }
.icon-weather-13:before { content: "\EA73" }
.icon-weather-14:before { content: "\EA74" }
.icon-weather-15:before { content: "\EA75" }
.icon-weather-16:before { content: "\EA76" }
.icon-weather-17:before { content: "\EA77" }
.icon-weather-2:before { content: "\EA78" }
.icon-weather-3:before { content: "\EA79" }
.icon-weather-4:before { content: "\EA7A" }
.icon-weather-5:before { content: "\EA7B" }
.icon-weather-6:before { content: "\EA7C" }
.icon-weather-7:before { content: "\EA7D" }
.icon-weather-8:before { content: "\EA7E" }
.icon-weather-9:before { content: "\EA7F" }
.icon-webcams:before { content: "\EA80" }
.icon-weekend:before { content: "\EA81" }
.icon-whatsapp:before { content: "\EA82" }
.icon-wind-direction:before { content: "\EA83" }
.icon-wind-speed:before { content: "\EA84" }
.icon-youtube:before { content: "\EA85" }


:root {
--icon-addthis: "\EA01";
    --icon-adult: "\EA02";
    --icon-alert: "\EA03";
    --icon-arrow-left: "\EA04";
    --icon-arrow-lg: "\EA05";
    --icon-arrow-right-2: "\EA06";
    --icon-arrow-right: "\EA07";
    --icon-bed: "\EA08";
    --icon-bike: "\EA09";
    --icon-blog: "\EA0A";
    --icon-boardercross: "\EA0B";
    --icon-business: "\EA0C";
    --icon-cable-car: "\EA0D";
    --icon-calendar: "\EA0E";
    --icon-camera: "\EA0F";
    --icon-cart: "\EA10";
    --icon-chairlift-2: "\EA11";
    --icon-chairlift-4: "\EA12";
    --icon-chairlift-6: "\EA13";
    --icon-check: "\EA14";
    --icon-children: "\EA15";
    --icon-close: "\EA16";
    --icon-cross: "\EA17";
    --icon-crosscountry: "\EA18";
    --icon-date: "\EA19";
    --icon-delete-forever: "\EA1A";
    --icon-download: "\EA1B";
    --icon-drop: "\EA1C";
    --icon-dropdown: "\EA1D";
    --icon-edit: "\EA1E";
    --icon-elements: "\EA1F";
    --icon-facebook: "\EA20";
    --icon-facilities: "\EA21";
    --icon-family-additional-member: "\EA22";
    --icon-family-restroom: "\EA23";
    --icon-family: "\EA24";
    --icon-filter: "\EA25";
    --icon-freeridestrecke: "\EA26";
    --icon-funpark-jatz: "\EA27";
    --icon-giftcard: "\EA28";
    --icon-golf-course: "\EA29";
    --icon-golf: "\EA2A";
    --icon-gondola-lift-6: "\EA2B";
    --icon-gondola-lift: "\EA2C";
    --icon-grandma: "\EA2D";
    --icon-grandpa: "\EA2E";
    --icon-health-safty: "\EA2F";
    --icon-height-railway: "\EA30";
    --icon-hike: "\EA31";
    --icon-hockey: "\EA32";
    --icon-hotel: "\EA33";
    --icon-info: "\EA34";
    --icon-instagram: "\EA35";
    --icon-kidspark: "\EA36";
    --icon-king-bed: "\EA37";
    --icon-landscape: "\EA38";
    --icon-language: "\EA39";
    --icon-last-minute: "\EA3A";
    --icon-lawinen: "\EA3B";
    --icon-like: "\EA3C";
    --icon-linkedin: "\EA3D";
    --icon-mail: "\EA3E";
    --icon-map: "\EA3F";
    --icon-masks: "\EA40";
    --icon-merchandise: "\EA41";
    --icon-minus: "\EA42";
    --icon-mountains: "\EA43";
    --icon-nature-people: "\EA44";
    --icon-news: "\EA45";
    --icon-night-shelter: "\EA46";
    --icon-open: "\EA47";
    --icon-pause: "\EA48";
    --icon-personalisierung: "\EA49";
    --icon-pinterest: "\EA4A";
    --icon-pipe: "\EA4B";
    --icon-play: "\EA4C";
    --icon-plus: "\EA4D";
    --icon-poi: "\EA4E";
    --icon-profile: "\EA4F";
    --icon-quiz: "\EA50";
    --icon-quote: "\EA51";
    --icon-rain-probability: "\EA52";
    --icon-rates: "\EA53";
    --icon-remove: "\EA54";
    --icon-restaurant: "\EA55";
    --icon-school: "\EA56";
    --icon-scroll-down: "\EA57";
    --icon-search: "\EA58";
    --icon-seminar: "\EA59";
    --icon-shopping_cart: "\EA5A";
    --icon-ski-freeride: "\EA5B";
    --icon-sledging: "\EA5C";
    --icon-slopes: "\EA5D";
    --icon-snow: "\EA5E";
    --icon-snowboard: "\EA5F";
    --icon-snowflake: "\EA60";
    --icon-snowshoe: "\EA61";
    --icon-snowshoeing: "\EA62";
    --icon-spa: "\EA63";
    --icon-speedstrecke: "\EA64";
    --icon-t-bar-lift: "\EA65";
    --icon-teen: "\EA66";
    --icon-teenager: "\EA67";
    --icon-tel: "\EA68";
    --icon-tickets: "\EA69";
    --icon-tiktok: "\EA6A";
    --icon-trailrunning: "\EA6B";
    --icon-tripadvisor: "\EA6C";
    --icon-twitter: "\EA6D";
    --icon-upload: "\EA6E";
    --icon-weather-1: "\EA6F";
    --icon-weather-10: "\EA70";
    --icon-weather-11: "\EA71";
    --icon-weather-12: "\EA72";
    --icon-weather-13: "\EA73";
    --icon-weather-14: "\EA74";
    --icon-weather-15: "\EA75";
    --icon-weather-16: "\EA76";
    --icon-weather-17: "\EA77";
    --icon-weather-2: "\EA78";
    --icon-weather-3: "\EA79";
    --icon-weather-4: "\EA7A";
    --icon-weather-5: "\EA7B";
    --icon-weather-6: "\EA7C";
    --icon-weather-7: "\EA7D";
    --icon-weather-8: "\EA7E";
    --icon-weather-9: "\EA7F";
    --icon-webcams: "\EA80";
    --icon-weekend: "\EA81";
    --icon-whatsapp: "\EA82";
    --icon-wind-direction: "\EA83";
    --icon-wind-speed: "\EA84";
    --icon-youtube: "\EA85";
    
}