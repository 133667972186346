.weather-item {
    line-height: calc(20/25);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(20rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(25rem / 16);
    }
}
.weather-item__head {
    border-bottom: calc(1rem / 16) solid var(--color-primary);
    padding: calc(16rem / 16) calc(5rem / 16);
}
.weather-item__content {
    padding: calc(20rem / 16) calc(5rem / 16) calc(10rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(30rem / 16) calc(5rem / 16) calc(20rem / 16);
    }
}
.weather-item__content__icon {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.weather-item__content__weather {
    font-size: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(50rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(70rem / 16);
    }
}
.weather-item__content__value {
    position: relative;
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(22rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(27rem / 16);
    }
}
.weather-item__content__value:not(:first-child) {
    margin-left: calc(10rem / 16);
    padding-left: calc(12rem / 16);

    @media screen and (min-width: 1400px) {
        margin-left: calc(18rem / 16);
        padding-left: calc(20rem / 16);
    }
}
.weather-item__content__value:not(:first-child):before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(-4rem / 16);
    bottom: calc(-4rem / 16);
    width: calc(1rem / 16);
    background: rgba(255,255,255,.3);
}
.weather-item__footer {
    font-size: calc(14rem / 16);
    border-top: calc(1rem / 16) solid rgba(255,255,255,.3);
    padding: calc(10rem / 16) calc(5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        padding: calc(20rem / 16) calc(5rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
        padding: calc(25rem / 16) calc(5rem / 16);
    }
}

.weather-item:not(:first-child) .weather-item__content,
.weather-item:not(:first-child) .weather-item__footer {

    @media screen and (min-width: 768px) {
        border-left: calc(1rem / 16) solid rgba(255,255,255,.3);
    }
}


.weather-item--sm .weather-item__content__value {
    @media screen and (min-width: 1200px) {
        font-size: calc(25rem / 16);
    }
}
.weather-item--sm .weather-item__content__small {
    font-size: calc(14rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
    }
}