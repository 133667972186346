.blog-author__item{
    line-height: 1.5;
}

.blog-author__img{
    width: 70px;
}

.quote__container{
    position: relative;
    border: .0625rem solid var(--color-primary);
    padding: 4rem 4rem 3.8rem 4rem;

    @media screen and (max-width: 767px){
        padding: 2rem 2rem 1.9rem 2rem;
    }
}

.quote__icon{
    position: absolute;
    display: block;
    font-size: 3.5rem;
    top: 0;
    transform: translateY(-70%);
    background-color: var(--color-primary-contrast);
    padding: 0 1rem;
    margin: 0 auto;
    left: 50%;
    margin-left: -3.5rem;

    @media screen and (max-width: 767px){
        font-size: 2.5rem;
        margin-left: -2.5rem;
    }
}

.page-wrapper--light .quote__icon {
    background-color: transparent;
}

.blog-margin-top--xs {

    @media screen and (max-width: 787px){
        padding-top: calc(40rem / 16) !important;
    }
}