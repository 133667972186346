.breadcrumb-container nav{
    position: relative;
}

.breadcrumb-container nav:after {
    @media screen and (max-width: 767px){
        content: '';
        position: absolute;
        right: -1px;
        top: 0;
        bottom: 0;
        width: 10px;
        box-shadow: -10px 0 16.5px 3px var(--color-secondary);
        background-color: var(--color-secondary);
    }
}

.accocart__breadcrumb .breadcrumb-container nav:after {
    display: none;
}

.page-wrapper--light .breadcrumb-container nav:after{
    background-color: #f6f2ef;
    box-shadow: -10px 0 16.5px 3px #f6f2ef;
}

.breadcrumb{
    @media screen and (max-width: 767px){
       padding: 0;
        margin-bottom: calc(5rem/16);
        flex-wrap: nowrap;
        overflow: auto;
        padding-right: 25px;
    }
}

.breadcrumb-item{
    white-space: nowrap;
}

.breadcrumb-container {
    padding-top: calc(90rem / 16);
    position: relative;

    @media screen and (min-width: 768px) {
        padding-top: calc(138rem / 16);
    }
}
.hero + .breadcrumb-container {
    padding-top: 0;
}
.breadcrumb-container--fixed{
    @media screen and (min-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}