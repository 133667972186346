.floating-label {
    position: absolute;
    left: calc(15rem / 16);
    top: calc(10rem / 16);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
    line-height: calc(34/18);

    @media screen and (min-width: 768px) {
        left: calc(20rem / 16);
        top: calc(12rem / 16);
    }
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label{
    transform: translateY(calc(-10rem / 16)) scale(.65);
}
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(calc(-7rem / 16)) scale(.65);
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(calc(-10rem / 16)) scale(.65);
}