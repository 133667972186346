.form-control {
    height: calc(48rem / 16);
    padding: calc(15rem / 16) calc(15rem / 16) calc(4rem / 16);
    font-size: calc(16rem / 16);
    color: currentColor;

    @media screen and (min-width: 768px) {
        height: calc(56rem / 16);
        padding: calc(18rem / 16) calc(20rem / 16) calc(8rem / 16);
        font-size: calc(18rem / 16);
    }
}

.form-group.has-value .form-control{
    padding-bottom: 0;
    padding-top: 15px;

    @media screen and (min-width: 768px){
        padding-top: 19px;
    }
}

.form-control:focus {
    color: currentColor;
    border-width: 2px;
}
.form-group {
    position: relative;
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(48rem / 16);
    }
}

.form-group:not(.select):after {
    content: var(--icon-check);
    font-family: iconfont;
    color: var(--color-success);
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 10px;
    display: none;

    @media screen and (min-width: 768px){
        top: 20px;
    }
}

.form-group.form-check:after {
    display: none;
}

.form-group.form-group--date-input:after{
    right: 38px;
    @media screen and (min-width: 768px){
       right: 60px;
    }
}

.form-group.is-valid:after {
    display: block;
}

.form-group--sm {
    margin-bottom: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(18rem / 16);
    }
}

.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: var(--color-default);
}
.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: var(--color-default);
    opacity:  1;
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-default);
}

/* no label */
.form-control--no-label {
    padding: calc(12rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(20rem / 16);
    }
}

/* colors */
.form-control--white:focus {
    color: #fff;
}
.form-control--white {
    border-color: #fff;
    color: #fff;
}
.form-control--white::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #fff;
}
.form-control--white::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #fff;
}
.form-control--white:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}

.form-control-label,
.form-text {
    line-height: calc(22/18);
}
.form-control-label + .form-text {
    margin-top: 0;
    margin-bottom: calc(10rem / 16);
}

.file-input .form-control {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.page-wrapper--light .form-control:not(.form-control--white) {
    color: var(--color-dark);
}

.form-control-addon.form-control-addon {
    position: absolute;
    right: 0;
    padding: calc(5rem / 16) calc(10rem / 16);
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(5rem / 16) calc(20rem / 16);
        font-size: calc(22rem / 16);
    }
}
.form-control-addon + .form-control {
    padding-right: calc(25rem / 16);

    @media screen and (min-width: 768px) {
        padding-right: calc(50rem / 16);
    }
}
.form-control-addon__circle {
    padding: calc(3rem / 16);
    font-size: calc(12rem / 16);
    display: block;
    width: calc(22rem / 16);
    height: calc(22rem / 16);
    border-radius: 50%;
    border: calc(1rem / 16) solid var(--color-primary);

    @media screen and (min-width: 768px) {
        width: calc(26rem / 16);
        height: calc(26rem / 16);
        font-size: calc(14rem / 16);
    }
}

/* form result infos */
.result-info__item {
    position: relative;
}
.result-info__item:not(:first-child) {
    @media screen and (min-width: 768px) {
        padding-left: calc(8rem / 16);
        margin-left: calc(8rem / 16);
    }
}
.result-info__item:not(:first-child):before {
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        left: 0;
        top: calc(8rem / 16);
        bottom: calc(8rem / 16);
        width: calc(1rem / 16);
        background: currentColor;
    }
}

.result-info__sort__icon {
    vertical-align: 0.5em;
    font-size: calc(6rem / 16);
    margin-left: calc(7rem / 16);
}
.result-info__sort--desc .result-info__sort__icon {
    transform: rotate(180deg);
}

.ui-datepicker {
    z-index: 1051 !important;
}
.ui-datepicker {
    @media screen and (max-width: 767px) {
        font-size: calc(16rem / 16);
    }
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.has-error .custom-checkbox__box{
    border-width: 3px;
}

.form-check-input.is-valid~.custom-checkbox__box,
.was-validated .form-check-input:valid~.custom-checkbox__box{
    border-color: var(--color-success);
}