.main-footer {
    position: relative;
    z-index: 1;
    font-size: calc(14rem / 16);
    line-height: calc(22/14);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        line-height: calc(30/18);
    }
}
.main-footer__bg {
    position: relative;
}
.main-footer__bg:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(-5rem / 16);
    background-image: url("/static/build/img/backgrounds/mountain.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom center;
    height: 60%;
}
.main-footer__main__logo {
    position: relative;
    width: calc(100rem / 16);
    margin-bottom: calc(30rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        margin-top: -7%;
        width: calc(120rem / 16);
    }

    @media screen and (min-width: 1200px) {
        margin-top: -9%;
        width: calc(150rem / 16);
    }
}
.main-footer__main {
    padding: 0 0 calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 0 calc(30rem / 16);
    }
}
.main-footer__main__title {
    letter-spacing: calc(1rem / 16);
    margin-bottom: calc(5rem / 16);
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(10rem / 16);
        font-size: calc(25rem / 16);
        line-height: calc(36/26);
    }
}
.main-footer__bottom {
    padding: calc(15rem / 16) 0 calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) 0 calc(28rem / 16);
    }
}
.main-footer__bottom a:hover {
    color: var(--color-primary);
}

.sitebyelements {
    height: calc(20rem / 16);
    width: auto;
}

.footer__divider{
    font-size: calc(20rem/16);
    line-height: 1;
}