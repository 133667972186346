.img-teaser {
    position: relative;
    box-shadow: 0 calc(20rem / 16) calc(35rem / 16) 0 rgba(0,0,0,0.2);
}
.img-teaser__img {
    transition: transform 200ms ease-in-out;
}
.img-teaser:hover .img-teaser__img {
    transform: scale(1.02);
}
.img-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(25,43,79,0) 0%, var(--color-secondary) 100%);
}

.img-teaser__content {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(10rem / 16) calc(15rem / 16);
    font-size: calc(14rem / 16);
    line-height: calc(25/14);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        padding: calc(20rem / 16) calc(22rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(20rem / 16);
        padding: calc(33rem / 16) calc(35rem / 16);
    }
}
.img-teaser__content__title {
    font-size: calc(20rem / 16);
    line-height: calc(35/30);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(30rem / 16);
    }
}

@media screen and (min-width: 768px) {
    .img-teaser--resorts:before,
    .img-teaser--hotels:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: opacity 300ms ease-in-out;
        z-index: 1;
        opacity: 0;
        background: var(--color-secondary);
    }
    .img-teaser--hotels:before {background: var(--color-hotels);}
    .img-teaser--resorts:before {background: var(--color-resorts);}

    .img-teaser:hover:before {
        opacity: .9;
    }
}

.img-teaser__content__logo {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: 1;
    padding: calc(5rem / 16) 0;
    width: calc(100rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) 0;
    }

    @media screen and (min-width: 1400px) {
        width: calc(200rem / 16);
    }
}
.img-teaser:hover .img-teaser__content__logo {
    opacity: 1;
}