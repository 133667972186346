.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--color-secondary-transparent);
    z-index: 10;
    text-align: center;
}
.loading-overlay--light,
.page-wrapper--light .loading-overlay{
    background: rgba(246, 242, 239, 0.7);
}
.loading-overlay--center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}