.ajax-modal__loading {
    min-height: 200px;
}

.ajax-modal-body{
    padding: calc(30rem / 16) calc(20rem / 16) calc(15rem / 16);

@media screen and (min-width: 768px){
    padding: calc(50rem / 16) 15rem calc(35rem / 16);
}
}