.shop-img-teaser {
    position: relative;
    box-shadow: 0 calc(10rem / 16) calc(35rem / 16) 0 rgba(0,0,0,0.2);
    margin-top: calc(20rem / 16);
    background: var(--color-secondary);
    color: #ffffff;
    font-size: calc(16rem / 16);
    line-height: calc(35/18);

    @media screen and (min-width: 1400px) {
        font-size: calc(18rem / 16);
    }
}
.shop-img-teaser__head {
    position: relative;
    margin-bottom: calc(-33rem / 16);
}
.shop-img-teaser__head:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(25,43,79,0) 0%, rgba(25,43,79,0.01) 65.04%, var(--color-secondary) 100%);
}
.shop-img-teaser__date {
    z-index: 1;
    position: absolute;
    right: calc(-10rem / 16);
    top: calc(-10rem / 16);
    border: calc(1rem / 16) solid var(--color-primary);
    padding: calc(15rem / 16) calc(10rem / 16);
    font-size: calc(12rem / 16);
    line-height: calc(10/16);

    @media screen and (min-width: 768px) {
        right: calc(-15rem / 16);
        top: calc(-15rem / 16);
        padding: calc(20rem / 16) calc(16rem / 16);
    }

    @media screen and (min-width: 1400px) {
        right: calc(-20rem / 16);
        top: calc(-20rem / 16);
        padding: calc(24rem / 16) calc(20rem / 16);
        font-size: calc(16rem / 16);
    }
}
.shop-img-teaser__date__item + .shop-img-teaser__date__item {
    margin-left: calc(5rem / 16);
    padding-left: calc(27rem / 16);
}
.shop-img-teaser__date__day {
    font-size: calc(40rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(52rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(70rem / 16);
    }
}
.shop-img-teaser__date__item + .shop-img-teaser__date__item .shop-img-teaser__date__day:before {
    content: '-';
    position: absolute;
    left: 0;
    top: 0;
}
.shop-img-teaser__date__badge {
    position: absolute;
    top: 100%;
    left: calc(-1rem / 16);
    right: calc(-1rem / 16);
    background: var(--color-primary);
    font-size: calc(12rem / 16);
    line-height: calc(23/16);
    padding: calc(6rem / 16) calc(2rem / 16) calc(4rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}

.shop-img-teaser__content {
    position: relative;
    padding: 0 calc(15rem / 16);

    @media screen and (min-width: 1200px) {
        padding: 0 calc(25rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: 0 calc(38rem / 16);
    }
}
.shop-img-teaser__content + .shop-img-teaser__content {
    margin-top: calc(15rem / 16);
    padding-top: calc(10rem / 16);
    border-top: calc(1rem / 16) solid rgba(255,255,255,.2);

    @media screen and (min-width: 768px) {
        margin-top: calc(25rem / 16);
        padding-top: calc(25rem / 16);
    }
}
.shop-img-teaser__content__subtitle {
    font-size: calc(18rem / 16);
    line-height: calc(29/20);

    @media screen and (min-width: 1400px) {
        font-size: calc(20rem / 16);
    }
}
.shop-img-teaser__content__title {
    font-size: calc(22rem / 16);
    line-height: calc(35/30);

    @media screen and (min-width: 1200px) {
        font-size: calc(26rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(30rem / 16);
    }
}
.shop-img-teaser__content__price {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(22rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(25rem / 16);
    }
}

.shop-img-teaser__btn {
    border: 0;
    font-size: calc(16rem / 16);
    line-height: calc(26/18);
    padding: calc(10rem / 16) 0;

    @media screen and (min-width: 1200px) {
        padding: calc(15rem / 16) 0;
        font-size: calc(18rem / 16);
    }
}