.social-info {
    position: absolute;
    bottom: calc(90rem / 16);
    right: calc(5rem / 16);
    z-index: 1;
    transform: rotate(90deg);
    transform-origin: right top;
    font-size: calc(16rem / 16);
    line-height: calc(23/16);
    text-shadow: 0 0 calc(5rem / 16) rgba(0,0,0,.5);

    @media screen and (min-width: 1680px) {
        right: calc(40rem / 16);
    }
}
.social-info__text {
    display: flex;
    align-items: center;
    letter-spacing: calc(.64rem / 16);
}
.social-info__text:after {
    content: '';
    background: var(--color-primary);
    width: calc(32rem / 16);
    height: calc(2rem / 16);
    display: inline-block;
    margin: 0 calc(24rem / 16) 0 calc(20rem / 16);
}
.social-info__item {
    transform: rotate(-90deg);
}
.social-info__item:not(:last-child) {
    margin-right: calc(26rem / 16);
}