.article-teaser {
    position: relative;
    box-shadow: 0 calc(10rem / 16) calc(35rem / 16) 0 rgba(0,0,0,0.2);
    margin-top: calc(20rem / 16);
}
.article-teaser__img {
    transition: transform 200ms ease-in-out;
}
.article-teaser:hover .article-teaser__img {
    transform: scale(1.02);
}
.article-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(25,43,79,0) 0%, var(--color-secondary) 100%);
}
.article-teaser__date {
    z-index: 1;
    position: absolute;
    right: calc(-10rem / 16);
    top: calc(-10rem / 16);
    border: calc(1rem / 16) solid var(--color-primary);
    padding: calc(15rem / 16) calc(10rem / 16);
    font-size: calc(12rem / 16);
    line-height: calc(10/16);

    @media screen and (min-width: 768px) {
        right: calc(-15rem / 16);
        top: calc(-15rem / 16);
        padding: calc(20rem / 16) calc(16rem / 16);
    }

    @media screen and (min-width: 1400px) {
        right: calc(-20rem / 16);
        top: calc(-20rem / 16);
        padding: calc(24rem / 16) calc(20rem / 16);
        font-size: calc(16rem / 16);
    }
}
.article-teaser__date__item + .article-teaser__date__item {
    margin-left: calc(5rem / 16);
    padding-left: calc(27rem / 16);
}
.article-teaser__date__day {
    font-size: calc(40rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(52rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(70rem / 16);
    }
}
.article-teaser__date__item + .article-teaser__date__item .article-teaser__date__day:before {
    content: '-';
    position: absolute;
    left: 0;
    top: 0;
}
.article-teaser__date__badge {
    position: absolute;
    top: 100%;
    left: calc(-1rem / 16);
    right: calc(-1rem / 16);
    background: var(--color-primary);
    font-size: calc(12rem / 16);
    line-height: calc(23/16);
    padding: calc(6rem / 16) calc(2rem / 16) calc(4rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}
.article-teaser__badge {
    z-index: 1;
    position: absolute;
    right: calc(-10rem / 16);
    top: calc(-10rem / 16);
    background: var(--color-primary);
    padding: calc(15rem / 16) calc(10rem / 16);
    font-size: calc(12rem / 16);
    line-height: calc(10/16);

    @media screen and (min-width: 768px) {
        right: calc(-15rem / 16);
        top: calc(-15rem / 16);
        padding: calc(20rem / 16) calc(16rem / 16);
    }

    @media screen and (min-width: 1400px) {
        right: calc(-20rem / 16);
        top: calc(-20rem / 16);
        padding: calc(24rem / 16) calc(20rem / 16);
        font-size: calc(16rem / 16);
    }
}
.article-teaser__content {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(10rem / 16) calc(15rem / 16);
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        padding: calc(15rem / 16) calc(18rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(20rem / 16);
        padding: calc(33rem / 16) calc(35rem / 16);
    }
}
.article-teaser__btn + .article-teaser__content{
    margin-bottom: calc(45rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(41rem/16);
    }
    @media screen and (min-width: 1200px){
        margin-bottom: calc(10rem/16);
    }
}
.article-teaser__content__title {
    font-size: calc(20rem / 16);
    line-height: calc(35/30);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(30rem / 16);
    }
}
.article-teaser__content__meta {
    line-height: calc(34/18);

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
    }
}
.article-teaser__content__meta__item {
    position: relative;
    display: inline-block;
}
.article-teaser__content__meta__item:not(:first-child) {
    padding-left: calc(6rem / 16);
    margin-left: calc(4rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: calc(10rem / 16);
        margin-left: calc(8rem / 16);
    }
}
.article-teaser__content__meta__item:not(:first-child):before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(6rem / 16);
    bottom: calc(8rem / 16);
    width: calc(1rem / 16);
    background: currentColor;
    opacity: .8;

    @media screen and (min-width: 768px) {
        top: calc(8rem / 16);
        bottom: calc(8rem / 16);
    }
}

/* sizes */
.article-teaser--sm .article-teaser__content__title {
    font-size: calc(18rem / 16);
    line-height: calc(24/18);
}
.article-teaser--sm .article-teaser__content {
    padding: calc(14rem / 16) calc(20rem / 16);
    font-size: calc(12rem / 16);
    text-transform: uppercase;
}
.article-teaser--sm .article-teaser__content__meta {
    font-size: calc(12rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
}
.article-teaser--sm .article-teaser__content__meta__item:not(:first-child) {
    padding-left: calc(10rem / 16);
    margin-left: calc(6rem / 16);
}
.article-teaser--sm .article-teaser__content__meta__item:not(:first-child):before {
    top: calc(5rem / 16);
    bottom: calc(5rem / 16);
}
.article-teaser--sm .article-teaser__date {
    right: calc(-12rem / 16);
    top: calc(-12rem / 16);
    padding: calc(16rem / 16) calc(22rem / 16);
    font-size: calc(12rem / 16);
}
.article-teaser--sm .article-teaser__date__day {
    font-size: calc(30rem / 16);
    margin-bottom: calc(8rem / 16);
}
.article-teaser--sm .article-teaser__date__badge {
    font-size: calc(11rem / 16);
}
.article-teaser__btn{
    position: absolute;
    bottom: 0;
    right: 0;
    left: auto;
    z-index: 1;
}
.article-teaser__content-icon{
    vertical-align: middle;
}
.btn.article-teaser__btn--wide{
    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(65rem/16);
    }
}