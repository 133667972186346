.teaser-scale-slider {
    padding: 0;
}
.teaser-scale-slider .slick-list {
    @media screen and (min-width: 768px) {
        padding: 0 26.2% !important;
    }
}
.teaser-scale-slider .slick-track {
    @media screen and (min-width: 768px) {
        display: flex;
    }
}
.teaser-scale-slider .slick-slide {
    @media screen and (min-width: 768px) {
        float: none;
        transform: scale(0.852) translateX(29%);
        transition: transform 450ms ease;
        pointer-events: none;
    }
}
.teaser-scale-slider .slick-current {
    @media screen and (min-width: 768px) {
        transform: scale(1);
        position: relative;
        pointer-events: auto;
        z-index: 1;
    }
}
.teaser-scale-slider .slick-current ~ .slick-slide {
    @media screen and (min-width: 768px) {
        transform: scale(0.852) translateX(-29%);
    }
}

.teaser-scale-slider .img-content-teaser__badge {
    @media screen and (max-width: 767px) {
        left: 0;
    }
}
.teaser-scale-slider .img-content-teaser__badge {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}
 .slick-current .img-content-teaser__badge {
     opacity: 1;
}
.teaser-scale-slider .img-content-teaser__content {
    transform: translateY(-30%);
    transition: transform 400ms ease-in-out;
}
.teaser-scale-slider .slick-current  .img-content-teaser__content {
    transform: translateY(-50%);
}
.teaser-scale-slider .img-content-teaser__content__subtitle,
.teaser-scale-slider .img-content-teaser__content__text {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}

.teaser-scale-slider .slick-current  .img-content-teaser__content__text,
.teaser-scale-slider .slick-current  .img-content-teaser__content__subtitle {
    opacity: 1;
}

/* small teaser slider */
.teaser-slider .slick-list {
    margin-top: calc(-55rem / 16) !important;
    margin-bottom: calc(-55rem / 16) !important;
}
.teaser-slider .slick-track {
    padding-top: calc(55rem / 16);
    padding-bottom: calc(55rem / 16);
}