.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    padding: 0;
    font-size: calc(18rem / 16);
    line-height: calc(26/18);
    color: var(--color-dark);
    display: flex;
}
.navbar-brand {
    margin: 0;
    flex-shrink: 0;
    width: calc(84rem / 16);
    height: calc(84rem / 16);
    background: var(--navbar-brand-bg);
    padding: calc(4rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: calc(-31rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        height: auto;
        min-height: calc(128rem / 16);
        align-self: stretch;
        padding: calc(12rem / 16);
    }

    @media screen and (min-width: 1200px) {
        width: calc(120rem / 16);
    }
}
.navbar-brand__img {
    width: calc(64rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(84rem / 16);
    }
}
.navbar__main {
    background: #ffffff;
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        white-space: nowrap;
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
    }
}
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;

    @media screen and (min-width: 768px) {
        display: flex;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}
.navbar__main .navbar-nav {
    padding: calc(24rem / 16);
    display: flex;
    list-style: none;
    margin: 0;

    @media screen and (min-width: 768px) {
        padding: 0;
        padding-left: calc(10rem / 16);
        height: calc(74rem / 16);
        flex-grow: 1;
    }

    @media screen and (min-width: 1400px) {
        padding-left: calc(29rem / 16);
    }
}
.navbar__main .navbar-nav > .nav-item {
    padding: 0 calc(8rem / 16);

    @media screen and (min-width: 1200px) {
        padding: 0 calc(10rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: 0 calc(20rem / 16);
    }
}
.navbar__main .navbar-nav > .nav-item + .nav-item {
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-left: calc(10rem / 16);
    }

    @media screen and (min-width: 1200px) {
        margin-left: calc(15rem / 16);
    }

    @media screen and (min-width: 1400px) {
        margin-left: calc(22rem / 16);
    }
}
.navbar__main .navbar-nav .nav-link {
    letter-spacing: calc(2rem / 16);
    position: relative;
    display: inline-block;
    padding: calc(5rem / 16) 0;
    color: inherit;

    @media screen and (min-width: 768px) {
        height: 100%;
        display: flex;
        align-items: center;
    }
}
.navbar__main .navbar-nav a.nav-link:not(.nav-link--logo):after {
    content: '';
    height: calc(4rem / 16);
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--brand-primary-color);
    position: absolute;
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left center;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.navbar__main .navbar-nav .nav-item.active > a.nav-link:after {
    opacity: 1;
    transform: scaleX(1);
}

.navbar__main .navbar-nav .nav-item:hover > a.nav-link:after {
    @media screen and (min-width: 768px) {
        opacity: 1;
        transform: scaleX(1);
    }
}
.navbar__main__btn {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: calc(5rem / 16) calc(12rem / 16);
    letter-spacing: normal;
    font-size: calc(14rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
    }
    @media screen and (min-width: 1400px) {
        padding: calc(5rem / 16) calc(34rem / 16);
    }
}
.navbar__main__btn.navbar__main__btn--icon {
    position: relative;
    font-size: calc(20rem / 16);
    line-height: 1;
    letter-spacing: normal;
    flex-grow: 1;
    padding: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        flex-grow: unset;
        padding: calc(5rem / 16) calc(10rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(5rem / 16) calc(20rem / 16);
        font-size: calc(22rem / 16);
    }
}
.navbar__main__btn--primary {
    background: var(--brand-primary-color);
    color: var(--color-secondary);
}
.navbar__main__btn--primary:hover,
.navbar__main__btn--primary:focus {
    background: var(--brand-primary-color-dark);
    color: var(--color-secondary);
    outline: none;
}
.navbar__main__btn--icon:after {
    content: '';
    height: calc(4rem / 16);
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--brand-primary-color);
    position: absolute;
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left center;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.navbar__main__btn--icon.is-active:after {
    opacity: 1;
    transform: scaleX(1);
}
.navbar__main__title {
    letter-spacing: calc(2rem / 16);
    position: relative;
    padding: 0 calc(20rem / 16);

    @media screen and (min-width: 768px) {
        white-space: normal;
        padding-left: calc(10rem / 16);
        padding-right: calc(25rem / 16);
        max-width: 22%;
    }

    @media screen and (min-width: 1200px) {
        max-width: 28%;
    }

    @media screen and (min-width: 1400px) {
        padding-left: calc(49rem / 16);
        padding-right: calc(50rem / 16);
    }
}
.navbar__main__title:after {
    @media screen and (min-width: 768px) {
        right: 0;
        content: var(--icon-arrow-left);
        font-family: iconfont;
        color: var(--brand-primary-color);
        position: absolute;
        font-size: calc(15rem / 16);
        transform: translateY(-50%);
        top: 50%;
    }
}

/* navbar small text  */
.navbar--sm .navbar__main {
    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(16rem / 16);
    }
}
.navbar--sm .navbar__main>ul>.nav-item {
    @media screen and (min-width: 768px) {
        padding: 0 calc(3rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: 0 calc(6rem / 16);
    }
}

.navbar__main-wrapper {
    display: flex;
    background: #ffffff;
}

.navbar__contact {
    position: absolute;
    right: 0;
    top: 100%;
    background: #fff;
    margin-top: calc(14rem / 16);
    border-top: calc(5rem / 16) solid var(--color-primary);
    letter-spacing: calc(.5rem / 16);
    padding: calc(2rem / 16) calc(5rem / 16) 0 calc(15rem / 16);
    box-shadow: 0 0 calc(6rem / 16) 0 rgba(0,0,0,.1);
    font-size: calc(14rem / 16);
    transition: transform 300ms ease-in-out;
    
    @media screen and (min-width: 1400px) {
        padding: calc(2rem / 16) calc(5rem / 16) 0 calc(30rem / 16);
        font-size: calc(18rem / 16);
    }
}
.navbar__contact.is-hidden {
    transform:translateX(100%);
}
.navbar__contact__img {
    max-width: calc(50rem / 16);

    @media screen and (min-width: 1400px) {
        max-width: calc(75rem / 16);
    }
}
.navbar__contact a:hover {
    text-decoration: underline;
}
.navbar__contact-close {
    position:absolute;
    width:calc(30rem/16);
    height:calc(30rem/16);
    background:var(--color-primary);
    top:calc((5rem/16)*-1);
    left:calc((30rem/16)*-1);
    border:0;
    z-index:1;
}
.navbar__contact-icon {
    position:absolute;
    top:50%;
    left:50%;
    transform:translateX(-50%) translateY(-50%)
}