.filter-group {
    background: var(--color-secondary);
    color: #fff;
    padding: calc(10rem / 16);
    box-shadow: 0 calc(5rem / 16) calc(20rem / 16) 0 rgba(0,0,0,0.2);

    @media screen and (min-width: 768px) {
        border: calc(1rem / 16) solid #fff;
        border-radius: calc(27.5rem / 16);
        box-shadow: 0 calc(5rem / 16) calc(30rem / 16) 0 rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 767px) {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }
}

.page-wrapper--light .filter-group {
    background: #fff;
    color: var(--color-secondary);
}


.filter-block {
    @media screen and (min-width: 768px) {
        margin-top: calc(60rem / 16);
        border: calc(1rem / 16) solid var(--color-primary);
    }
}
.filter-block .filter-group {
    @media screen and (min-width: 768px) {
        transform: translateY(-50%);
        margin-bottom: 0;
    }
}

/* mobile scrolling filter */

.filter-group-container {
    @media screen and (max-width: 767px){
        position: relative;
    }
}
.filter-group-container:after {
    @media screen and (max-width: 767px){
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        box-shadow: 0 0 calc(20rem / 16) calc(20rem / 16) var(--color-secondary);
    }
}
.page-wrapper--light .filter-group-container:after,
.bg-light-grey .filter-group-container:after {
    @media screen and (max-width: 767px) {
        box-shadow: 0 0 calc(20rem / 16) calc(30rem / 16) var(--color-light-grey);
    }
}
.filter-group-container:before {
    @media screen and (max-width: 767px){
        content: var(--icon-scroll-down);
        font-family: iconfont;
        position: absolute;
        right: calc(8rem / 16);
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        z-index: 1;
        font-size: calc(11rem / 16);
    }
}
.filter-group__item:last-child {
    @media screen and (max-width: 767px) {
        padding-right: calc(20rem / 16);
    }
}