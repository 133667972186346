.ui-datepicker-inline .ui-state-highlight a {
    background-color: rgba(244,205,60,0.7);
    border-radius: 50%;
    color: var(--color-secondary);
    opacity: 0.7;

    @media screen and (max-width: 767px){
        border: 0.125rem solid transparent;
    }
}

.ui-datepicker-inline td a,
.ui-datepicker-inline td span {
    @media screen and (max-width: 767px){
        padding: calc(12rem/16) 0.25rem;
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}