.nav-tabs .nav-link {
    border: 0;
}
.nav-tabs--default {
    background: var(--color-secondary);
    color: #fff;
    padding: calc(10rem / 16);
    display: flex;
    align-items: center;
    margin-bottom: calc(40rem / 16);
    box-shadow: 0 calc(5rem / 16) calc(20rem / 16) 0 rgba(0,0,0,0.2);

    @media screen and (min-width: 768px) {
        border: calc(1rem / 16) solid #fff;
        border-radius: calc(27.5rem / 16);
        margin-bottom: calc(100rem / 16);
        box-shadow: 0 calc(5rem / 16) calc(30rem / 16) 0 rgba(0,0,0,0.2);
        font-size: calc(16rem / 16);
        padding: calc(5rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
        padding: calc(10rem / 16);
    }

    @media screen and (max-width: 767px) {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }
}
.nav-tabs--default .nav-link {
    padding: calc(10rem / 16) calc(20rem / 16) calc(8rem / 16);
    line-height: calc(24/18);
    border-radius: calc(18.5rem / 16);
    transition: background 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding: calc(5rem / 16) calc(12rem / 16) calc(4rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(6rem / 16) calc(25rem / 16) calc(5rem / 16);
    }
}
.nav-tabs--default .nav-item + .nav-item {
    margin-left: calc(2rem / 16);

    @media screen and (min-width: 1200px) {
        margin-left: calc(4rem / 16);
    }
}
.nav-tabs--default .active.nav-link {
    background: var(--color-primary);
    color: var(--color-primary-contrast);
}

/* nav tabs with icons */
.nav-tabs--icons {
    margin-bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(34rem / 16);
    }

    @media screen and (max-width: 767px) {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }
}
.nav-tabs--icons .nav-link {
    border-radius: 0;
    text-align: center;
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
    text-transform: uppercase;
    color: var(--color-secondary);
    font-size: calc(16rem / 16);
    line-height: calc(29/20);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.nav-tabs--icons .nav-link.active {
    background: transparent;
    color: var(--color-secondary);
}
.nav-link__icon {
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    border-radius: 50%;
    background: #fff;
    color: var(--color-primary);
    transition: color 200ms ease-in-out, background 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        width: calc(78rem / 16);
        height: calc(78rem / 16);
    }
}
.nav-link.active .nav-link__icon {
    background: var(--color-primary);
    color: var(--color-primary-contrast);
}
.nav-link__icon__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(24rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(35rem / 16);
    }
}

/* light theme adaptions */
.bg-light-grey .nav-tabs--default,
.page-wrapper--light .nav-tabs--default {
    background: #fff;
    color: var(--color-secondary);
}
.page-wrapper--light .bg-secondary .nav-tabs--default {
    background: var(--color-secondary);
    color: #fff;
}

.nav-tabs-container {
    @media screen and (max-width: 767px){
        position: relative;
    }
}
.page-wrapper--light .bg-secondary .nav-tabs-container:after,
.nav-tabs-container:after {
    @media screen and (max-width: 767px){
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        box-shadow: 0 0 calc(20rem / 16) calc(30rem / 16) var(--color-secondary);
        pointer-events: none;
    }
}
.page-wrapper--light .nav-tabs-container:after,
.bg-light-grey .nav-tabs-container:after {
    @media screen and (max-width: 767px) {
        box-shadow: 0 0 calc(20rem / 16) calc(30rem / 16) var(--color-light-grey);
    }
}
.nav-tabs-container:not(.hide-arrows):before {
    @media screen and (max-width: 767px){
        content: var(--icon-scroll-down);
        font-family: iconfont;
        position: absolute;
        right: calc(8rem / 16);
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        z-index: 1;
        font-size: calc(15rem / 16);
        pointer-events: none;
    }
}

.nav-tabs-container .nav-tabs {
    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }
}
.nav-tabs-container .nav-item:last-child {
    @media screen and (max-width: 767px) {
        padding-right: calc(20rem / 16);
    }
}

.nav-tabs--bordered-content {
    @media screen and (min-width: 768px) {
        margin-top: calc(60rem / 16);
        border: calc(1rem / 16) solid var(--color-primary);
        padding: 0 calc(30rem / 16);
    }
}
.nav-tabs--bordered-content .nav-tabs {
    @media screen and (min-width: 768px) {
        transform: translateY(-50%);
        margin-bottom: 0;
    }
}
.nav-tabs--bordered-content .tab-content {
    padding: calc(20rem / 16) 0 0;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) 0 calc(80rem / 16);
    }
}