.chart {
    width: calc(150rem / 16);
    height: calc(150rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(255rem / 16);
        height: calc(255rem / 16);
    }
}
.chart__item {
    width: 100%;
    height: auto;
}

/* chart legend  */
.legend {
    font-size: calc(14rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}
.legend + .legend {
    margin-top: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(20rem / 16);
    }
}
.legend__info {
    width: calc(15rem / 16);
    height: calc(15rem / 16);
    margin-right: calc(7rem / 16);
    border-radius: 50%;
    border: calc(1rem / 16) solid rgba(255,255,255,0.5);
    background: linear-gradient(214.51deg, #27B1D7 0%, #07668C 100%);

    @media screen and (min-width: 768px) {
        width: calc(17rem / 16);
        height: calc(17rem / 16);
        margin-right: calc(10rem / 16);
    }
}
.legend--middle .legend__info { background: linear-gradient(214.51deg, #E96262 0%, #C22341 100%);}
.legend--hard .legend__info { background: linear-gradient(214.51deg, #595956 0%, #2B2B2A 100%);}
.legend--freeride .legend__info { background: linear-gradient(209.94deg, #F4CD3C 0%, #D38014 100%);}