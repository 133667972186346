.state-box {
    width: calc(64rem / 16);
    height: calc(64rem / 16);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    padding: calc(5rem / 16);
}
.state-box--closed .state-box__value,
.state-box--closed .state-box__icon {
    opacity: .3;
}
.state-box__value {
    font-size: calc(16rem / 16);
    line-height: 1;
}
.state-box__value__total {
    font-size: calc(12rem / 16);
}
.state-box__icon {
    font-size: calc(23rem / 16);
}