.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 0.5em;

    @media screen and (min-width: 768px) {
        margin-bottom: 2em
    }
}
.wysiwyg--sm > p,
.wysiwyg--sm > ol,
.wysiwyg--sm > ul,
.wysiwyg--sm > table {
    margin-bottom: calc(10rem / 16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

.wysiwyg ul {
    list-style: none;
    padding-left: 0;
}
.wysiwyg ul li {
    position: relative;
    padding-left: calc(22rem / 16);
}
.wysiwyg ul li:before {
    content: '';
    position: absolute;
    width: calc(6rem / 16);
    height: calc(6rem / 16);
    top: calc(12rem / 16);
    left: 0;
    background: var(--color-primary);

    @media screen and (min-width: 768px) {
        width: calc(9rem / 16);
        height: calc(9rem / 16);
    }
}
.wysiwyg ul li + li {
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(12rem / 16);
    }

    @media screen and (min-width: 1400px) {
        margin-top: calc(16rem / 16);
    }
}

.wysiwyg small {
    line-height: calc(20rem / 16);
    display: inline-block;
}

/* table */
.wysiwyg table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border: 0;
    color: currentColor;
    text-align: left;

    @media screen and (max-width: 767px) {
        display: block;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
}

.wysiwyg table thead th,
.table thead th {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
    bottom: 0;
    border-bottom: calc(1rem / 16) solid var(--color-primary);
    padding: calc(6rem / 16) calc(8rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
        padding: calc(14rem / 16) calc(40rem / 16);
    }
}
.wysiwyg table thead th,
.wysiwyg table tbody th,
.table thead th,
.table tbody th {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
}
.wysiwyg table tbody th,
.table tbody th {
    border-right: calc(1rem / 16) solid rgba(255,255,255,0.3);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
}
.wysiwyg table td, .wysiwyg table th,
.table td, .table th {
    border: 0;
    padding: calc(6rem / 16) calc(8rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(40rem / 16);
    }
}
.wysiwyg tbody tr +  tr,
.table tbody tr + tr {
    border-top: calc(1rem / 16) solid rgba(255,255,255,0.3);
}
.table td:first-child, .table th:first-child, .wysiwyg table td:first-child, .wysiwyg table th:first-child {
    padding-left: 0;
}
.table tbody th:last-child, .wysiwyg table tbody th:last-child {
    border-right:0;
}

.page-wrapper--light .wysiwyg tbody tr,
.page-wrapper--light  .table tbody tr {
    border-top: calc(1rem / 16) solid rgba(43,43,42,0.1);
}
.page-wrapper--light .wysiwyg a {
    color: currentColor;
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
    text-decoration: underline;
}