.media-text {
    position: relative;
    overflow: hidden;
}
.media-text__content {
    position: relative;
    z-index: 1;
    margin-top: calc(-25rem / 16);
    padding: calc(8rem/16) calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem/16) 0 calc(30rem / 16);
        margin-top: 0;
        font-size: calc(19rem / 16);
        line-height: calc(35/18);
    }
}

.media-text__content__offset {
    @media screen and (min-width: 768px){
        margin-left: -36.3%;
    }
}
.media-text__img {
    box-shadow: 0 calc(20rem / 16) calc(50rem / 16) 0 rgba(0,0,0,0.2);
}
.media-text__content__subtitle {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    font-size: calc(20rem / 16);
    text-transform: none;

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
        line-height: calc(35/25);
    }
}

/* background box */
.media-text--bg {
    padding-bottom: calc(40rem / 16);
    color: var(--color-dark);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(100rem / 16);
    }
}
.page-wrapper--light .media-text--bg {color: var(--color-default)}

.media-text--bg:before {
    content: '';
    background: var(--color-light-grey);
    background-image: url("/static/build/img/backgrounds/decorator.svg");
    background-size: cover;
    position: absolute;
    top: calc(65rem / 16);
    width: 100%;
    bottom: 0;
    right: 0;

    @media screen and (min-width: 768px){
        top: calc(90rem / 16);
        width: 67.5vw;
    }
}

.bg-light-grey.media-text--bg:before,
.page-wrapper--light .media-text--bg:not(.bg-secondary):before {
    background-color: var(--color-secondary);
}

/* media right */
.media-text--media-right.media-text--bg:before {
    right: auto;
    left: 0;
}
.media-text--media-right .media-text__content__offset {
    @media screen and (min-width: 768px) {
        margin-left: 0;
    }
}
.media-text--text-only .media-text__content__offset {
    margin-left: 0;
}
.media-text--text-only .media-text__content {
    margin-top: 0;
}

/* animations */
.tab-pane.active .media-text__content {
    animation: mediaTextContent;
    animation-duration: 800ms;
    animation-fill-mode: forwards;
}
.tab-pane.active .media-text__img {
    animation: mediaTextImage;
    animation-duration: 800ms;
    animation-fill-mode: forwards;
}
@keyframes mediaTextContent{
    0% {
        opacity: 0;
    }
    15% {
        transform: translateX(10%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes mediaTextImage {
    0% {
        opacity: 0;
    }
    15% {
        transform: translateX(-10%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}