.counter-box {
    font-size: calc(14rem / 16);
    line-height: calc(20/16);
    letter-spacing: calc(1rem / 16);
    max-width: 100%;
    transition: opacity 200ms ease-in-out;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px){
        width: calc(206rem / 16);
        font-size: calc(16rem / 16);
    }
}
.counter-box__count {
    border: calc(1rem / 16) solid var(--color-primary);
    border-bottom: 0;
    background: var(--color-primary);
    color: var(--color-secondary);
    padding: calc(4rem / 16) calc(5rem / 16) calc(3rem / 16);
}
.counter-box__content {
    border: calc(1rem / 16) solid var(--color-primary);
    border-bottom: 0;
    padding: calc(15rem / 16) calc(10rem / 16);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: 768px) {
        padding: calc(25rem / 16) calc(10rem / 16);
    }
}
.counter-box__content__title {
    font-size: calc(18rem / 16);
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.counter-box__content__icon {
    color: var(--color-primary);
    font-size: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(60rem / 16);
    }
}

.counter-box__content__discount {
    font-size: calc(14rem / 16);
}
.counter-box__content__price{
    position: relative;
    font-size: calc(18rem / 16);
}

/* inactive state */
.is-inactive.counter-box {
    opacity: .4;
}
.is-inactive.counter-box:hover {
    opacity: .9;
}
.is-inactive .counter-box__content__icon {
    color: #fff;
}
.is-inactive .counter-box__count,
.is-inactive .counter-box__content {
    border-color: #fff;
}
.is-inactive .counter-box__count {
    background: #fff;
}