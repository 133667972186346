.date-box {
    border: calc(1rem / 16) solid var(--color-primary);
    font-size: calc(12rem / 16);
    line-height: calc(10/16);
    width: calc(100rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(16rem / 16);
        width: calc(130rem / 16);
    }
}
.date-box__content {
    padding: calc(10rem / 16) calc(5rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(20rem / 16) calc(18rem / 16);
    }
}
.date-box__content__day {
    font-size: calc(40rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(50rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(70rem / 16);
    }
}
.date-box__badge {
    background: var(--color-primary);
    color: var(--color-secondary);
    font-size: calc(12rem / 16);
    line-height: calc(23/16);
    padding: calc(6rem / 16) calc(2rem / 16) calc(4rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(16rem / 16);
    }
}