.title-block + .accordion {
    @media screen and (max-width: 767px){
        margin-top: calc(40rem/16);
    }
}


.accordion .card {
    background: transparent;
    border: 0;
    padding-bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(14rem / 16);
    }
}
.accordion .card + .card {
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(33rem / 16);
    }
}
.accordion .card .card-header {
    background: transparent;
    margin-bottom: 0;
    border: 0;
}
.accordion__header-link {
    position: relative;
    display: block;
    width: 100%;
    min-height: calc(47rem / 16);
    font-size: calc(16rem / 16);
    line-height: calc(35/22);
    padding-right: calc(45rem / 16);
    font-family: var(--font-default-medium);
    font-weight: var(--font-medium-weight);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
        padding-right: calc(60rem / 16);
    }
}
.accordion__header-link:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: calc(40rem / 16);
    height: calc(1rem / 16);
    background: var(--color-primary);

    @media screen and (min-width: 768px) {
        right: calc(56rem / 16);
    }
}

.accordion__header-toggle {
    position: absolute;
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    border: calc(1rem / 16) solid var(--color-primary);
    background: var(--color-primary);
    right: 0;
    bottom: calc(-10rem / 16);
    transition: background 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        width: calc(56rem / 16);
        height: calc(56rem / 16);
        bottom: calc(-14rem / 16);
    }
}
.accordion__header-link.collapsed .accordion__header-toggle {
    background: transparent;
    color: var(--color-primary);
}
.accordion__header-toggle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transition: transform 200ms ease-in-out;
    color: var(--color-primary-contrast);
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
    }
}
.accordion__header-link.collapsed .accordion__header-toggle__icon {
    transform: translateX(-50%) translateY(-50%);
    color: var(--color-primary);
}
.accordion__header-link:focus {
    outline: none;
}
.accordion__header-link:not(.collapsed),
.accordion__header-link.collapsed:hover,
.accordion__header-link.collapsed:focus {
    color: var(--color-primary);
}
.accordion__body{
    position: relative;
    border: calc(1rem / 16) solid var(--color-primary);
    border-top: 0;
    padding: calc(20rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16) calc(60rem / 16) calc(22rem / 16) calc(28rem / 16);
    }
}