.iframe-map {
    /*outline: 2px solid var(--color-primary);*/
    border: none;
}
@media(min-width: 1200px){
    .iframe-map {
        height: var(--iframe-map-height-desktop);
        /*outline: 2px solid var(--color-primary);*/
        border: none;
    }
}
@media(min-width: 768px) and (max-width: 1199px){
    .iframe-map {
        height: var(--iframe-map-height-tablet);
    }
}
@media(max-width: 767px){
    .iframe-map {
        height: var(--iframe-map-height-mobile);
    }
}