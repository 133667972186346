/* josefin-sans-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/static/fonts/josefin-sans-v32-latin-regular.woff2') format('woff2');
}
/* josefin-sans-600 - latin */
@font-face {
    font-display: swap;
    font-family: 'Josefin Sans Medium';
    font-style: normal;
    font-weight: 600;
    src: url('/static/fonts/josefin-sans-v32-latin-600.woff2') format('woff2');
}
/* josefin-sans-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Josefin Sans Bold';
    font-style: normal;
    font-weight: 700;
    src: url('/static/fonts/josefin-sans-v32-latin-700.woff2') format('woff2');
}