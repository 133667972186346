.custom-radio{
    position: relative;
    padding-left: calc(24rem/16);
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}

.custom-radio:after {
     clear: both;
}

.custom-radio>label{
    cursor: pointer;
}

.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    left: 0;
    top: calc(1rem / 16);
    overflow: hidden;
    border: calc(1rem/16) solid currentColor;
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 39%;
    height: 38%;
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}

.custom-radio__icon {
    font-size: calc(20rem/16);
    width: 100%;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .custom-radio__icon {
        font-size: calc(30rem/16);
        width: 100%;
        text-align: center;
    }

}


.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
    font-size: calc(14rem / 16);
    line-height: calc(24/14);
}
.custom-radio__input:disabled ~ .custom-radio__box,
.custom-radio__input:disabled ~ .custom-radio__text {
    opacity: .3;
    color: currentColor;
}
.custom-radio__text__img {
    height: calc(15rem / 16);
    width: auto;
    vertical-align: -.2em;
    margin-left: calc(5rem / 16);
    margin-right: calc(5rem / 16);
    transition: filter 200ms ease-in-out;
}
.is-inactive .custom-radio__text__img {
    filter: grayscale(100%);
}

.custom-radio--md {
    @media screen and (min-width: 768px) {
        padding-left: calc(28rem/16);
    }
}
.custom-radio--md .custom-radio__text {
    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        line-height: calc(26/18);
    }
}
.custom-radio--md .custom-radio__text__img {
    @media screen and (min-width: 768px) {
        height: calc(20rem / 16);
    }
}
.custom-radio--lg {
    @media screen and (min-width: 768px) {
        padding-left: calc(28rem/16);
    }
}
.custom-radio--lg .custom-radio__text {
    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
        line-height: calc(26/25);
    }
}

/* highlight active style */
.custom-radio--highlight-active .custom-radio__text,
.custom-radio--highlight-active .custom-radio__box{
    opacity: .3;
    transition: opacity 200ms ease-in-out;
}
.custom-radio--highlight-active:focus .custom-radio__text,
.custom-radio--highlight-active:hover .custom-radio__text,
.custom-radio--highlight-active:focus .custom-radio__box,
.custom-radio--highlight-active:hover .custom-radio__box {
    opacity: .6;
}
.custom-radio--highlight-active .custom-radio__input:checked ~ .custom-radio__text,
.custom-radio--highlight-active .custom-radio__input:checked ~ .custom-radio__box {
    opacity: 1;
}

/* radio button styled like buttons */
.custom-radio--btn {
    padding: 0;
    display: inline-block;
    line-height: .6;
    text-align: center;
}
.custom-radio--btn:not(:last-child) {
    margin-right: calc(2rem / 16);
}
.custom-radio--btn .custom-radio__box {
    display: none;
}
.custom-radio--btn .custom-radio__text {
    padding: calc(10rem / 16) calc(20rem / 16) calc(8rem / 16);
    line-height: calc(24/18);
    border-radius: calc(18.5rem / 16);
    transition: background 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding: calc(5rem / 16) calc(12rem / 16) calc(4rem / 16);
        font-size: calc(16rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(7rem / 16) calc(25rem / 16) calc(5rem / 16);
    }
}
.custom-radio--btn .custom-radio__input:checked ~ .custom-radio__text {
    font-family: var(--font-default-bold);
    font-weight: 400;
    background: var(--color-primary);
    color: var(--color-secondary);
}