.img-text-slide {
    position: relative;
    margin-bottom: calc(60rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        min-height: calc(62.3vw*0.75 + calc(100rem / 16));
        padding-bottom: calc(100rem / 16);
    }
}
.img-text-slide__box {
    position: relative;
}
.page-wrapper--light .img-text-slide:before {
    content: '';
    background: var(--color-secondary);
    /*background-image: url("/static/build/img/backgrounds/decorator-blue.svg");*/
    background-image: url("/static/build/img/backgrounds/decorator.svg");
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: calc(100rem / 16);
    right: 0;
    width: 100%;

    @media screen and (min-width: 768px) {
        width: 55vw;
    }
}
.img-text-slide__box__content {
    position: relative;
    z-index: 1;
    padding: calc(30rem / 16) 0;
/*
    @media screen and (min-width: 768px) {
        padding: 0;
    }*/
}
.img-text-slide__img {
    box-shadow: 0 calc(20rem / 16) calc(50rem / 16) 0 rgba(0,0,0,0.2);

    @media screen and (min-width: 768px) {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 62.3vw;
    }
}
.img-text-slide__img__mark {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: calc(45rem / 16);
    line-height: 1;
    transform: translateY(50%);

    @media screen and (min-width: 768px) {
        font-size: calc(100rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(180rem / 16);
    }
}

/* media right */
.img-text-slide--media-right:before {
    left: 0;
    right: auto;
}
.img-text-slide--media-right .img-text-slide__img {
    left: auto;
    right: 0;
}
.img-text-slide--media-right .img-text-slide__img__mark {
    left: auto;
    right: 0;
}

/* box colors */
.img-text-slide--light-grey.img-text-slide {
    color: var(--color-dark);
}
.img-text-slide--light-grey.img-text-slide:before {
    content: '';
    background: var(--color-light-grey);
    background-image: url("/static/build/img/backgrounds/decorator.svg");
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: calc(100rem / 16);
    right: 0;
    width: 100%;

    @media screen and (min-width: 768px) {
        width: 55vw;
    }
}
.img-text-slide--secondary.img-text-slide:before {
    content: '';
    background: var(--color-secondary);
    background-image: url("/static/build/img/backgrounds/decorator.svg");
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: calc(100rem / 16);
    right: 0;
    width: 100%;

    @media screen and (min-width: 768px) {
        width: 55vw;
    }
}