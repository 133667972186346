.title-block--cse {
    text-align: center;
    padding-top: calc(100rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(180rem / 16);
    }
}
.title-block--cse .title-block__title {
    font-size: calc(32rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
    text-transform: uppercase;
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        font-size: calc(50rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(70rem/16);
    }
}
.cse-teaser {
    font-size: calc(14rem / 16);
    cursor: pointer;

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }

}
.cse-teaser__body {
    padding: calc(15rem / 16) calc(8rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(30rem / 16);
    }
}
.cse-teaser__hl {
    font-size: calc(20rem / 16);
    line-height: calc(35/30);
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        font-size: calc(26rem / 16);
    }
}