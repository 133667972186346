.modal-header {
    padding: calc(30rem / 16) calc(40rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px){
        padding: calc(50rem / 16) calc(20rem / 16) calc(35rem / 16);
    }
}
.modal-body {
    padding: calc(30rem / 16) calc(24rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px){
        padding: calc(50rem / 16) calc(20rem / 16) calc(35rem / 16);
    }
}
.modal-content {
    color: #fff;
    border: none;
}
.modal-body--sm {
    font-size: calc(18rem / 16);
}
.modal-title {
    font-size: calc(22rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(40rem / 16);
        line-height: calc(58/40);
    }
}
.modal-title--sm {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}
.modal-close, .modal__close{
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    background: var(--color-primary);
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    z-index: 1;

    @media screen and (min-width: 768px) {
        width: calc(55rem / 16);
        height: calc(55rem / 16);
    }
}
.modal-close__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(22rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(26rem / 16);
    }
}

.modal-lg .modal-header {
    padding-bottom: calc(16rem / 16);
}

.modal-xl {
    @media screen and (min-width: 768px) {
        max-width: calc(1340rem / 16);
        width: 100%;
    }
}

@media (min-width: 767px) {
    .multiticket__list-item:last-child {
        position: relative;
    }

    .multiticket__list-item:last-child:before {
        position: absolute;
        content: '';
        width: 1px;
        background-color: var(--color-primary);
        top: -10%;
        left: 0;
        height: 120%;
    }
}
