.facility {

}

.facility__h3 {
    color: var(--color-primary);
    font-size: calc(30rem/16);
}

@media screen and (min-width: 768px) {
    .facility__h3 {
        font-size: calc(50rem/16);
    }

}



/* navigation */

@media screen and (min-width: 768px) {
    .facility-filter {
        display: flex;
        align-items: center;
    }

    .facility-filter__btn .custom-radio__text {
        line-height: calc(24 / 9);
        font-size: calc(9rem/16);
        padding: 0 calc(27rem/16) calc(16rem/16) calc(27rem/16);

    }

    .facility .filter-group-container {
        border-bottom: var(--color-white) solid 1px;
    }

    .facility div.filter-group-container::before {
        display: none;
    }

}

@media screen and (min-width: 1200px) {
    .facility-filter__btn .custom-radio__text {
        line-height: calc(24 / 18);
        font-size: calc(16rem/16);
    }
}

.facility-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.facility-filter__container.filter-group-container:before {
    display: none;
}

.facility-filter__btn .custom-radio__input ~ .custom-radio__text {
    height: 100%;
    display: flex;
    align-items: center;
    opacity: 0.3;
}

.facility-filter__btn .custom-radio__input ~ .custom-radio__icon {
    opacity: 0.3;
    margin-top: calc(23rem/16);
}

.facility-filter__btn .custom-radio__box {
    display: none;
}

.facility-filter__btn .custom-radio__text {
    padding: 0 calc(9rem/16) calc(2rem/16) calc(9rem/16);
    line-height: calc(24 / 10);
    font-size: calc(10rem/16);
    text-transform: uppercase;
    font-weight: bold;
}


.facility-filter__btn .custom-radio__input:checked ~ .custom-radio__text {
    opacity: 1;
    position: relative;
}

.facility-filter__btn .custom-radio__input:hover ~ .custom-radio__text {
    opacity: 1;
}


.facility-filter__btn .custom-radio__input ~ .custom-radio__text:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0.25rem;
    background: var(--color-primary);
    opacity: 0;
    transition: transform 200ms ease-in-out;
}

.facility-filter__btn .custom-radio__input:checked ~ .custom-radio__text:before {
    opacity: 1;
}

.facility-filter__btn .custom-radio__input:checked ~ .custom-radio__icon,
.facility-filter__btn .custom-radio__input:hover ~ .custom-radio__icon {
    opacity: 1;
}


.facility-filter__btn .custom-radio__input:checked ~ .custom-radio__text:after {
    height: 0.25rem;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-primary);
    position: absolute;
    opacity: 1;
}

.facility .filter-group-container:before {
    content: none;
}








/* ACCORDION */

@media screen and (min-width: 768px) {
    .facility-accordion__title {
        font-size: calc(17rem/16);
        line-height: unset;
    }

    .facility-accordion__toggle-icon {
        font-size: calc(9rem/16);
    }

}

@media screen and (min-width: 1200px) {
    .facility-accordion__badge {
        font-size: calc(15rem/16);
    }

}

.facility__data-row {
    margin-top: calc(39rem/16);
}

.page-wrapper--light .facility-accordion__header-link.collapsed .facility-accordion__header {
    border: var(--color-dark) solid 1px;
    color: var(--color-dark);
}

.facility-accordion__header-link.collapsed .facility-accordion__header {
    font-size: calc(17rem/16);
    text-transform: uppercase;
    border: var(--color-white) solid 1px;
    background-color: unset;
    color: var(--color-white);
}

.facility-accordion__header  {
    text-transform: uppercase;
    border: var(--color-primary) solid 1px;
    background-color: var(--color-primary);
    color: var( --color-secondary);
    display: flex;
    justify-content: space-between;
}

.facility-accordion__title {
    padding: calc(20rem/16);
    font-weight: bold;
    font-size: calc(14rem/16);
    line-height: calc(14/14);
    flex: 1;
}

.page-wrapper--light .facility-accordion__toggle {
    border-left: var(--color-dark) solid calc(1rem/16);
}

.page-wrapper--light .facility-accordion__toggle-icon {
    color: var(--color-dark);
}

.facility-accordion__toggle {
    border-left: var(--color-white) solid calc(1rem/16);
    height: 100%;
    padding: 0 calc(24rem/16);
    display: flex;
    align-items: center;
}

.facility-accordion__toggle-icon {
    color: var(--color-white);
    transition: transform 200ms ease-in-out;
    font-size: calc(7rem/16);
}

.facility-accordion__header-link[aria-expanded="true"] .facility-accordion__toggle{
    border-color: var(--color-secondary);
}
.facility-accordion__header-link[aria-expanded="true"] .facility-accordion__toggle-icon{
    color: var(--color-secondary);
    transform: rotate(180deg);
}

.facility-accordion__collapse[aria-expanded="true"] .facility-accordion__toggle-icon{
    transform: rotate(180deg);
}

.facility-accordion__badge {
    padding: calc(7rem/16) calc(15rem/16) calc(3rem/16) calc(15rem/16);
    height: fit-content;
    border-radius: 25px;
    margin-right: calc(18rem/16);
    font-size: calc(10rem/16);
}

.facility-accordion__badge.all-open {
    background-color: var(--color-success);
    color: var(--color-white);
}

.facility-accordion__badge.open {
    background-color: var(--color-white);
    color: var(--color-secondary);
    font-weight: bold;
}

.facility-accordion__badge.all-closed {
    background-color: var(--color-white);
    color: var(--color-danger);
    font-weight: bold;
}



/* ACCORDION BODY */

@media screen and (min-width: 768px) {
    .facility .accordion__body {
        padding: calc(31rem/16) calc(21rem/16);
    }

    .facility-accordion__col-header {
        font-size: calc(22rem/16);
    }

    .facility-accordion__col-subheader {
        font-size: calc(14rem/16);
    }

    .facility-accordion__col-content {
        font-size: calc(18rem/16);
        line-height: calc(20/18);
    }

    .facility-accordion__col-icon--small .icon {
        font-size: calc(7rem/16);
    }

    .facility-accordion__col-icon--small {
        width: calc(20rem/16);
        height: calc(20rem/16);
        bottom: calc(-5rem/16);
        right: calc(-5rem/16);
    }

}

@media screen and (min-width: 1200px) {


}

.facility .btn {
    font-weight: bold;
    font-size: calc(16rem/16);
}

.facility .font-bold {
    font-weight: bold;
}

.facility .accordion__body {
    padding: calc(20rem/16) calc(20rem/16);
}

.facility-accordion__row:not(:last-child) {
    margin-bottom: calc(31rem/16);
}

.facility-accordion__col-header {
    font-size: calc(16rem/16);
    font-weight: 600;
}

.facility-accordion__col-subheader {
    font-size: calc(10rem/16);
    font-weight: 600;
}

.facility-accordion__col-content {
    font-size: calc(14rem/16);
    line-height: calc(20/14);
}

.facility-accordion__col-img {
    width: calc(50rem/16);
    height: calc(50rem/16);
    background-color: var(--color-white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.facility-accordion__col-icon {
    color: var(--color-secondary);
    font-size: calc(25rem/16);
}

.facility-accordion__col-icon--slopes {
    color: var(--color-white);
    font-size: calc(15rem/16);
}

.facility-accordion__col-icon--activities {
    color: var(--color-secondary);
    font-size: calc(20rem/16);
}

.facility-accordion__col-img.openSlope {
    border: var(--color-success) solid calc(2rem/16);
}

.facility-accordion__col-img.closedSlope {
    border: var(--color-danger) solid calc(2rem/16);
}

.facility-accordion__col-img.inPreparationSlope {
    border: #ff8701 solid calc(2rem/16);
}

.facility-accordion__col-icon--small {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(14rem/16);
    height: calc(14rem/16);
    border-radius: 50%;
    bottom: calc(-3rem/16);
    right: calc(-3rem/16);
}

.facility-accordion__col-icon--small.openSlope {
    background-color: var(--color-success);
}

.facility-accordion__col-icon--small.closedSlope {
    background-color: var(--color-danger);
}

.facility-accordion__col-icon--small.inPreparationSlope {
    background-color: #ff8701;
}

.facility-accordion__col-icon--small .icon {
    font-size: calc(5rem/16);
}

.facility-accordion__collapse-item:not(:last-child) {
    border-bottom: #ffffff4f solid calc(1rem/16);
    margin-bottom: calc(17rem/16);
    padding-bottom: calc(22rem/16);
}





/* Slopes */
.facility__slope-information {
    display: flex;
    font-size: calc(16rem/16);
    margin-bottom: calc(28rem/16);
}

.facility-accordion__slope-difficulty {
    width: calc(15rem/16);
    height: calc(15rem/16);
    border-radius: 50%;
    display: block;
    margin-right: calc(10rem/16);
}

.facility-accordion__col-img.difficulty-hard,
.facility-accordion__slope-difficulty.difficulty-hard {
    background-color: #000000;
}

.facility-accordion__col-img.difficulty-mid,
.facility-accordion__slope-difficulty.difficulty-mid {
    background-color: var(--color-danger);
}

.facility-accordion__col-img.difficulty-easy,
.facility-accordion__slope-difficulty.difficulty-easy {
    background-color: #3D79D3;
}

/* Lifts */
.facility__status-information {
    display: flex;
    flex-wrap: wrap;
    gap: 0 calc(16rem/16);
    font-size: calc(16rem/16);
    margin-bottom: calc(28rem/16);
}

.facility__information-item {
    white-space: nowrap;
    display: flex;
    gap: calc(10rem/16);
    align-items: center;
}

.facility-accordion__status {
    width: calc(16rem/16);
    height: calc(16rem/16);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.icon:before {
        font-size: calc(8rem/16);
    }
    .icon:before {
        font-size: calc(8rem/16);
    }
}

.facility-accordion__status.open {
    background-color: var(--color-success);
}
.facility-accordion__status.in-preparation {
    background-color: #ff8701;
}
.facility-accordion__status.closed {
    background-color: var(--color-danger);
}