.offscreen-filter {
    display: block;
}
@media screen and (max-width: 767px) {
    .offscreen-filter {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transform: translateX(100%);
        transition: 200ms ease-in-out;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background: var(--color-secondary);
        z-index: 11;
        padding: 0 0 calc(30rem / 16);
    }
    .offscreen-filter.is-open {
        transform: translateX(0);
    }
    .offscreen-filter__head {
        padding: calc(14rem / 16) calc(20rem / 16) calc(12rem / 16);
        margin-bottom: calc(40rem / 16);
        box-shadow: 0 0 calc(24rem / 16) rgba(0,0,0,.5);
    }
    .offscreen-filter__head__close {
        font-size: calc(14rem / 16);
    }
    .offscreen-filter__btn {
        width: 100%;
    }
}