.contact-teaser {
    border: calc(1rem / 16) solid var(--color-primary);
    line-height: calc(28/22);

    @media screen and (min-width: 1200px) {
        font-size: calc(22rem / 16);
    }
}
.contact-teaser__content {
    padding: calc(20rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(20rem / 16) calc(75rem / 16);
    }
}
.contact-teaser__content__detail + .contact-teaser__content__detail {
    margin-top: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(20rem / 16);
    }
}