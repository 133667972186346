.nav-teaser {
    position: relative;

    @media screen and (max-width: 767px) {
        margin: calc(10rem / 16) 0;
        padding: 0 calc(24rem / 16);
    }
}
.nav-teaser:after {
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: radial-gradient(circle, rgba(16,58,93,0.6) 0%, rgba(16,58,93,0.4) 100%);
    }
}
.nav-teaser__img {
    transition: transform 200ms ease-in-out;
}
.nav-teaser:hover .nav-teaser__img {
    transform: scale(1.03);
}
.nav-teaser__content {
    font-size: calc(14rem / 16);
    line-height: calc(32/22);
    color: var(--color-secondary);

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16);
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        font-size: calc(16rem / 16);
        color: #fff;
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(22rem / 16);
    }
}
.nav-teaser__circle {
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    border-radius: 50%;
    background: var(--color-secondary);
    position: relative;

    @media screen and (min-width: 768px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
        background: #fff;
    }

    @media screen and (min-width: 1200px) {
        width: calc(80rem / 16);
        height: calc(80rem / 16);
    }
}
.nav-teaser__circle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(20rem / 16);
    color: #fff;

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
        color: var(--color-secondary);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(42rem / 16);
    }
}
.nav-teaser__circle__icon.icon-landscape {
    font-size: .95rem;

    @media screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
    @media screen and (min-width: 1200px) {
        font-size: 1.9rem;
    }
}