.custom-checkbox {
    position: relative;
    padding-left: calc(30rem / 16);
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    border: 1px solid var(--color-primary);
    width: calc(20rem / 16);
    height: calc(20rem / 16);
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(9rem / 16);
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    font-size: calc(14rem / 16);
    line-height: calc(24/14);
}

/* sizes */
.custom-checkbox--lg .custom-checkbox__text {
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.custom-checkbox--lg .custom-checkbox__box {
    top: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        top: calc(4rem / 16);
    }
}

/* checkbox styled like buttons */
.custom-checkbox--btn {
    padding: 0;
    display: inline-block;
    line-height: .6;
    text-align: center;
}
.custom-checkbox--btn:not(:last-child) {
    margin-right: calc(2rem / 16);
}
.custom-checkbox--btn .custom-checkbox__box {
    display: none;
}
.custom-checkbox--btn .custom-checkbox__text {
    padding: calc(5rem / 16) calc(12rem / 16) calc(4rem / 16);
    line-height: calc(24/18);
    border-radius: calc(18.5rem / 16);
    transition: background 200ms ease-in-out;
    border: calc(1rem / 16) solid var(--color-primary);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(7rem / 16) calc(25rem / 16) calc(5rem / 16);
    }
}
.custom-checkbox--btn:focus .custom-checkbox__input:not(:checked) ~ .custom-checkbox__text,
.custom-checkbox--btn:hover .custom-checkbox__input:not(:checked) ~ .custom-checkbox__text {
    color: var(--color-primary);
}
.custom-checkbox--btn .custom-checkbox__input:checked ~ .custom-checkbox__text {
    font-family: var(--font-default-bold);
    font-weight: 400;
    background: var(--color-primary);
    color: var(--color-secondary);
}