.product-box {
    border-left: 1px solid var(--color-primary);
    border-right: 1px solid var(--color-primary);

    @media screen and (min-width: 1400px) {
        font-size: calc(25rem / 16);
        line-height: calc(42/25);
    }
}
.product-box__head {
    display: flex;
}
.product-box__head:after,
.product-box__head:before {
    content: '';
    height: calc(1rem / 16);
    background: var(--color-primary);
    flex-grow: 1;
    margin-left: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(20rem / 16);
    }
}
.product-box__head:before {
    margin-left: 0;
    margin-right: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: 0;
        margin-right: calc(20rem / 16);
    }
}
.product-box__head__title {
    transform: translateY(-50%);
    max-width: 70%;
}
.product-box__content {
    padding: calc(15rem / 16) calc(30rem / 16) calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(50rem / 16) calc(45rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(30rem / 16) calc(70rem / 16) calc(55rem / 16);
    }
}
.product-box__btn {
    border: 0;
    font-size: calc(16rem / 16);
    line-height: calc(26/18);
    padding: calc(10rem / 16) 0;

    @media screen and (min-width: 1200px) {
        padding: calc(15rem / 16) 0;
        font-size: calc(18rem / 16);
    }
}
.product-box__btn[disabled] {
    opacity: .65;
    pointer-events: none;
}