.datepicker-container:after {
    content: var(--icon-calendar);
    font-family: iconfont;
    position: absolute;
    right: calc(20rem / 16);
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-primary);
    font-size: calc(16rem / 16);
}

.datepicker-box {
    background: transparent;
    box-shadow: none;
    border: calc(1rem / 16) solid #fff;
    color: currentColor;
    position: relative;
    padding: 0;
    font-size: calc(12rem / 16);
    line-height: calc(17/12);
    letter-spacing: calc(1rem / 16);
    cursor: pointer;
}
.datepicker-box__datepicker {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
}
.datepicker-box__desc {
    padding: calc(8rem / 16) calc(5rem / 16) calc(6rem / 16);
    border-bottom: calc(1rem / 16) solid #fff;
    font-size: calc(16rem / 16);
}
.datepicker-box__content {
    padding: calc(16rem / 16) calc(5rem / 16) calc(12rem / 16);
}
.datepicker-box__content__day {
    font-size: calc(35rem / 16);
    line-height: calc(34/35);
}
.ui-datepicker-calendar td.highlight {
    background:#e8eeec;
}
.ui-datepicker-calendar td.is-in-range {
    background:rgba(32,91,65,.55);
}
.ui-datepicker-calendar .ui-state-disabled {
    opacity:.35;
    pointer-events:none;
}
.ui-datepicker-calendar .ui-state-disabled.ui-datepicker-current-day, .ui-datepicker-calendar .ui-state-disabled.highlight {
    opacity:1;
}