.cta-box {
    display: block;
    background-image: url("/static/build/img/backgrounds/decorator-small.svg");
    background-size: cover;
    background-repeat: repeat-y;
    background-blend-mode: soft-light;
    padding: calc(25rem / 16);
    font-size: calc(16rem / 16);
    line-height: calc(23/16);
    pointer-events: auto; /* need in slider */

    @media screen and (min-width: 768px) {
        margin-top: auto;
        padding: calc(25rem / 16) calc(30rem / 16);
        width: calc(280rem / 16);
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
    }

    @media screen and (min-width: 1400px) {
        width: calc(347rem / 16);
        padding: calc(45rem / 16) calc(40rem / 16);
    }
}
.cta-box:first-child {
    @media screen and (min-width: 768px) {
        margin-bottom: calc(-80rem / 16);
    }
}
.cta-box__title {
    letter-spacing: calc(2rem / 16);
    margin-bottom: calc(20rem / 16);
    font-size: calc(20rem / 16);
    line-height: calc(45/40);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(40rem / 16);
    }
}

.cta-box__btn {
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(43,43,42,0.2);
}

.cta-box--sm .cta-box__title {
    letter-spacing: calc(2rem / 16);
    margin-bottom: calc(20rem / 16);
    font-size: calc(18rem / 16);
    line-height: calc(45/40);
    
    @media screen and (min-width: 1400px) {
        font-size: calc(22rem / 16);
    }
}

.cta-box__capacity + .cta-box__capacity {
    margin-top: calc(10rem / 16);
    padding-top: calc(10rem / 16);
    border-top: calc(1rem / 16) solid rgba(255, 255, 255, 0.4);
}
.cta-box__state {
    width: calc(26rem / 16);
    height: calc(26rem / 16);
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 calc(7rem / 16) 0 rgba(0,0,0,.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10rem / 16);
    color: var(--color-success);
}
.cta-box__state--danger {
    color: var(--color-danger);
}
/*List Styling*/
.cta-box__list{
    text-align: start;
}
.cta-box__list > li:before{
    content: '';
    width: calc(11rem/16);
    height: calc(11rem/16);
    background: var(--color-secondary);
    position: absolute;
    left: 0;
    top: calc(3rem/16)
}
.cta-box__list > li{
    position: relative;
    padding-left: calc(20rem/16);
    display: flex;
    align-items: center;
}
.cta-box__list > li + li{
    margin-top: calc(10rem/16);
}
.cta-box--spinner{
    max-width: calc(160rem/16);
}