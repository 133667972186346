.img-slider .slick-list {
    @media screen and (min-width: 768px) {
        padding: 0 12% !important;
    }
    @media screen and (min-width: 1400px) {
        padding: 0 17.4% !important;
    }
}
.img-slider .slick-slide {
    float: none;
    transform: scale(0.87) translateX(8%);
    transition: transform 250ms cubic-bezier(0.68, -0.55, 0.25, 1.05),-webkit-transform 250ms ease-in-out;
    pointer-events: none;
}
.img-slider .slick-current {
    transform: scale(1);
    z-index: 1;
    pointer-events: auto;
}
.img-slider .slick-current ~ .slick-slide {
    transform: scale(0.87) translateX(-8%);
}