.img-content-teaser {
    position: relative;
    font-size: calc(14rem / 16);
    line-height: calc(20/14);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
        line-height: calc(35/18);
    }
}
.img-content-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: .2;
    transition: opacity 200ms ease-in-out;
}
.slick-current .img-content-teaser:after,
.img-content-teaser:hover:after {
    opacity: 0;
}
.img-content-teaser__content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    padding: calc(20rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        max-width: calc(540rem / 16);
        margin: 0 auto;
    }
}

.img-content-teaser__content__title {
    font-size: calc(30rem / 16);
    line-height: 1;
    text-shadow: 0 0 15px #525252;

    @media screen and (min-width: 768px) {
        font-size: calc(40rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(60rem / 16);
    }
}
.img-content-teaser__content__subtitle {
    font-size: calc(14rem / 16);
    line-height: 1;
    text-shadow: 0 0 15px #525252;

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(30rem / 16);
    }
}

.img-content-teaser__content__text .btn{
    box-shadow: 0 2px 13px 0 #666666;
}

.img-content-teaser__img {
    transition: transform 200ms ease-in-out;
}
.img-content-teaser:hover .img-content-teaser__img {
    transform: scale(1.02);
}