:root {
    --slider-arrow-color: var(--color-default);
    --slider-dot-background: #fff;
    --slider-dot-active-background: #fff;
    --slider-dot-margin-top: 0;
}
.slider {
    line-height: 0;
}

/* default slider arrows */
.slider .slick-arrow {
    width: calc(35rem / 16);
    height: calc(35rem / 16);
    border-radius: 50%;
    border: calc(2rem / 16) solid var(--color-primary);
    color: currentColor;

    @media screen and (min-width: 768px) {
        width: calc(55rem / 16);
        height: calc(55rem / 16);
    }

    @media screen and (min-width: 1400px) {
        width: calc(67rem / 16);
        height: calc(67rem / 16);
    }
}
.slider .slick-arrow:before {
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.slider .slick-arrow.slick-disabled {
    cursor: default;
}

/* slider arrows outside */
.slider-container {
    @media screen and (min-width: 768px) {
        padding: 0 calc(80rem / 16);
    }
    @media screen and (min-width: 1400px) {
        padding: 0 calc(120rem / 16);
    }
}
.slider-container .slick-arrow {
    left: calc(10rem / 16);
}
.slider-container .slick-arrow.slick-next {
    left: auto;
    right: calc(10rem / 16);
}
.slider-container .slick-track {
    display: flex;
}
.slider.slider-container .slick-arrow {
    @media screen and (max-width: 767px) {
        visibility: hidden;
    }
}

/* slider dots*/
.slider .slick-dots {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
    z-index: 1;
}
.slider .slick-dots>li {
    margin-left: calc(2rem / 16);
    margin-right: calc(2rem / 16);
}
.slider .slick-dots > li > button {
    font-size: 0;
    width: calc(40rem / 16);
    height: calc(4rem / 16);
    border-radius: 0;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        width: calc(65rem / 16);
    }
}
.slick-dots > li > button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: var(--color-primary);
    transform: translateY(100%);
    transition: 200ms ease-in-out;
}
.slick-dots>li>button:hover:before,
.slick-dots > .slick-active > button:before {
    transform: translateX(0);
}

.slider--dots-relative .slick-dots {
    position: relative;
    text-align: center;
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(36rem / 16);
    }
}

/* slider with normal gutter */
.slider--with-gutter {
    overflow: hidden;
}
.slider--with-gutter .slick-list {
    margin: 0 calc(-20rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0 calc(-15rem/16);
    }

    @media screen and (min-width: 1400px) {
        margin: 0 calc(-38rem / 16);
    }

}
.slider--with-gutter .slider-item {
    padding: 0 calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(15rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: 0 calc(38rem / 16);
    }
}

/* slider with small gutter */
.slider--with-gutter--sm .slick-list {
    @media screen and (min-width: 1400px) {
        margin: 0 calc(-24rem / 16);
    }
}

.slider--with-gutter--sm .slider-item {
    @media screen and (min-width: 1400px) {
        padding: 0 calc(24rem / 16);
    }
}



/* light theme adaptions */
.page-wrapper--light .slick-dots >li >button:hover,
.page-wrapper--light .slick-dots > li > button  {
    background: var(--color-dark-grey);
}