.hero {
    position: relative;

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
    }
}
.hero__content-wrapper {
    @media screen and (min-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
    }
}
.hero__content {
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: calc(40rem / 16);
        padding-top: calc(216rem / 16);
    }

    @media screen and (min-width: 1680px) {
        padding-right: calc(30rem / 16);
        padding-left: calc(30rem / 16);
    }
}
.hero__content.hero__content--text-only {
    padding-top: calc(120rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(150rem / 16) 0;
    }
}

.hero__content__bottom {
    margin-top: calc(-30rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: auto;
        margin-bottom: calc(-90rem / 16);
    }

    @media screen and (min-width: 1680px) {
        padding-right: calc(30rem / 16);
        padding-left: calc(30rem / 16);
    }
}
.hero__content__top {
    padding-bottom: calc(120rem / 16);
    padding: calc(60rem / 16) 0;

    @media screen and (max-width: 767px) {
        height: 50vh;
        padding: 1.75rem 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}
.hero__content__top__title {
    font-size: calc(38rem/16);
    line-height: calc(100/90);
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size: calc(70rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(90rem/16);
    }
}

.hero__image,
.hero__image-slider-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-image: var(--image-src-xs) !important;
    filter: opacity(0.7);
    @media screen and (min-width: 768px) {
        background-image: var(--image-src) !important;
        height: 100%;
        filter: opacity(1);
    }
}
.hero__image-slider-container .slick-list,
.hero__image-slider-container .slick-track {
    height: 100%;
}

.hero__image-slider-container + .hero__content-wrapper {
    pointer-events: none;
}
.hero__content-clickable {
    pointer-events: auto;
}

/* scroll info */
.hero__scroll {
    position: absolute;
    bottom: calc(20rem / 16);
    left: 50%;
    transform: translateX(-50%);
    width: calc(47rem / 16);
    height: calc(47rem / 16);
    border-radius: 50%;
    border: calc(2rem / 16) solid var(--color-primary);
    z-index: 1;
    font-size: calc(8rem / 16);
}

/* small content */
.hero--content-sm .hero__content__top__title {
    font-size: calc(32rem/16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(50rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(70rem/16);
    }
}

.hero__content__title {
    font-size: calc(40rem / 16);
    line-height: calc(105/100);

    @media screen and (min-width: 768px) {
        font-size: calc(100rem / 16);
    }
}

.hero__content__count {
    margin-top: calc(15rem / 16);
}
.hero__content__count__value {
    font-size: calc(38rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(70rem / 16);
    }
}

.hero__image:after {
    content: '';
    pointer-events: none;
    position: absolute;
    bottom: calc(-1rem / 16);
    left: 0;
    right: 0;
    height: calc(296rem / 16);
    background: linear-gradient(180deg, rgba(25,43,79,0) 0%, var(--color-secondary) 100%);
}
.page-wrapper--light .hero__image:after {
    background: linear-gradient(180deg, rgba(25,43,79,0) 0%, var(--color-light-grey) 100%);
}

/* hero background video */
.hero__video {
    overflow: hidden;
    position: relative;

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
.hero__video .hero__video__video {
    height: calc(50vh - calc(72rem / 16));
    width: 100%;
    position: relative;

    @media screen and (min-width: 768px) {
        height: 100vh;
        min-height: 50rem;
    }
}
.hero__video .video-js {
    background: var(--color-secondary);
}
.hero__video .video-js .vjs-tech {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    width: auto;
    height: auto;

    @media screen and (max-width: 767px) {
        max-height: 100%;
    }
}
.hero__video .hero__video__video--no-scale.video-js .vjs-tech {
    top: auto;
    left: auto;
    transform: none;
    min-width: unset;
    min-height: unset;
    width: 100%;
    height: 100%;
}
.hero__video .vjs-big-play-button{
    display: none;
}
.hero__video .vjs-poster {
    background-size: cover;
}
.hero__video + .hero__content-wrapper .hero__content__top {
    @media screen and (max-width: 767px) {
        height: auto;
        padding: calc(30rem / 16) 0;
    }
}
.hero__video + .hero__content-wrapper .hero__content__bottom {
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
}

.hero__portal .hero__spacer {
    height: calc(130rem/16);
}
