.page-switch {
    height: 100dvh;
    padding-top: calc(120rem / 16);
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (min-width: 768px) {
        padding-top: calc(70rem / 16);
    }
}
.page-switch:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(circle, rgba(22,22,21,0.5) 0%, rgba(22,22,21,0.2) 100%);
    pointer-events: none;
}
.page-switch__title {
    padding: 0 calc(30rem / 16) calc(40rem / 16);
    z-index: 1;
    position: relative;

    @media screen and (min-width: 768px) {
        padding: 0;
        pointer-events: none;
        max-width: 100%;
        width: 33%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}
.page-switch__item {
    position: relative;
    line-height: calc(22/16);
    justify-content: space-between;
    align-items: center;
    padding: calc(20rem / 16) calc(15rem / 16);
    background: rgba(0,0,0,.3);
    flex-grow: 1;

    @media screen and (min-width: 768px) {
        background: transparent;
        padding: calc(100rem / 16) calc(15rem / 16);
        font-size: calc(20rem / 16);
        line-height: calc(35/20);
        width: 33.3333%;
        justify-content: flex-end;
        height: calc(100vh - calc(70rem / 16));
    }

    @media screen and (min-width: 1400px) {
        padding: calc(100rem / 16) calc(50rem / 16);
    }
}
.page-switch--full-bg .page-switch__item {
    max-height: calc(140rem / 16);

    @media screen and (min-width: 768px) {
        max-height: none;
    }
}
.page-switch__item + .page-switch__item {
    @media screen and (max-width: 767px) {
        border-top: calc(1rem / 16) solid #fff;
    }
}

@media screen and (min-width: 768px) {
    .page-switch__item:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: opacity 300ms ease-in-out;
        z-index: 1;
        opacity: 0;
        background: var(--color-secondary);
    }
    .page-switch__item--hotels:before {background: var(--color-hotels);}
    .page-switch__item--resorts:before {background: var(--color-resorts);}
    .page-switch__item--waldhuus:before {background: var(--color-waldhuus);}
    .page-switch__item--mph:before {background: var(--color-mph);}

    .page-switch__item:hover:before {
        opacity: .9;
    }
}

.page-switch__item__title {
    font-size: calc(20rem / 16);
    line-height: calc(35/30);
    transition: transform 250ms ease-in-out, opacity 350ms ease-in-out;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
        letter-spacing: calc(2rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(30rem / 16);
    }
}
.page-switch__item:hover .page-switch__item__title {
    @media screen and (min-width: 768px) {
        transform: translateY(calc(10rem / 16));
        opacity: 0;
    }
}
.page-switch__item__content {
    @media screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        transform: translateY(-45%);
        left: 0;
        right: 0;
        opacity: 0;
        transition: transform 250ms ease-in-out, opacity 350ms ease-in-out;
        z-index: 1;
        padding: 0 calc(15rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: 0 calc(50rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .page-switch__item:hover .page-switch__item__content {
        transform: translateY(-50%);
        opacity: 1;
    }
}

.page-switch__item__content__logo {
    width: calc(100rem / 16);
    height: auto;
    
    @media screen and (min-width: 768px) {
        width: 40%;
        max-width: calc(216rem / 16);
    }
}

.page-switch__item__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-image: var(--image-src-xs) !important;
    background-repeat: no-repeat;

    @media screen and (min-width: 768px) {
        background-image: var(--image-src) !important;
    }
}

.page-switch__quicklink {
    position: relative;
    line-height: 1;
    font-size: calc(24rem / 16);
    justify-content: space-between;
    align-items: center;
    padding: calc(20rem / 16) calc(15rem / 16);
    flex-grow: 1;
    z-index: 1;
}
.page-switch__quicklink .icon-facilities {
    font-size: calc(28rem / 16);
}
.page-switch__quicklink + .page-switch__quicklink:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(1rem / 16);
    background: #fff;
    opacity: .5;
}