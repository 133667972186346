.sticky-button {
    position: fixed;
    left: 2.5rem;
    bottom: 2rem;
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    z-index: 11;
    overflow: hidden;
    box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.25);
    transition: background-color ease-in-out 0.25s;

    @media screen and (max-width: 767px) {
        left: 1rem;
        bottom: 1rem;
        height: 5rem;
        width: 5rem;
    }
}
.sticky-button--text {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-mountains);
    border: calc(2rem / 16) solid #fff;
}
.sticky-button--hotels {
    background: var(--color-hotels);
}
.sticky-button--waldhuus {
    background: var(--color-waldhuus);
}
.sticky--button--resorts {
    background: var(--color-resorts);
}
.sticky--button--resorts {
    background: var(--color-mph);
}
.sticky-button__content {
    padding: calc(4rem / 16);
    font-size: calc(11rem / 16);
    line-height: 1.2;
    text-align: center;

    @media screen and (min-width: 768px) {
        padding: calc(8rem / 16);
        font-size: calc(16rem / 16);
        line-height: 1.4;
    }
}
.sticky-button__img{
    overflow: hidden;
    height: 100%;
    width: auto;
}

.sticky-button:hover {
    cursor: pointer;
}