.img-lightbox-teaser {
    position: relative;
    box-shadow: 0 calc(20rem / 16) calc(35rem / 16) 0 rgba(0,0,0,0.2);
}
.img-lightbox-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(25,43,79,0) 0%, var(--color-secondary) 100%);
}
.img-lightbox-teaser__img {
    transition: transform 200ms ease-in-out;
}
.img-lightbox-teaser:hover .img-lightbox-teaser__img {
    transform: scale(1.02);
}
.img-lightbox-teaser__content {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(10rem / 16) calc(15rem / 16);
    font-size: calc(14rem / 16);


    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(17rem / 16) calc(30rem / 16);
    }
}
.img-lightbox-teaser__content__title {
    font-size: calc(20rem / 16);
    line-height: calc(40/35);

    @media screen and (min-width: 768px) {
        font-size: calc(26rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(35rem / 16);
    }
}