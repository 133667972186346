.number-spinner .number-spinner__input {
    border-color: var(--color-light-grey);
    color: currentColor;
    height: calc(45rem / 16);
    padding: calc(5rem / 16);

    @media screen and (min-width: 768px){
        padding: calc(5rem / 16) calc(18rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(5rem / 16) calc(18rem / 16);
        height: calc(56rem / 16);
    }
}
.number-spinner input::-webkit-outer-spin-button,
.number-spinner input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
.number-spinner input[type=number] {
    -moz-appearance: textfield;
}
.btn.number-spinner__btn,
.number-spinner__btn {
    width: calc(45rem / 16);
    background: var(--color-secondary);
    padding: calc(8rem / 16) calc(5rem / 16);
    overflow: hidden;
    position: relative;

    @media screen and (min-width: 1200px) {
        width: calc(54rem / 16);
        padding: calc(12rem / 16) calc(20rem / 16);
    }
}
.number-spinner__btn:hover {
    background: var(--color-secondary-dark);
}


/* primary colors */
.number-spinner--primary .number-spinner__input {
    border-color: var(--color-primary);
}
.number-spinner--primary .number-spinner__btn {
    background: var(--color-primary);
    color: var(--color-secondary);
}

.is-inactive .number-spinner__input {
    border-color: #fff;
}
.is-inactive .number-spinner__btn {
    background: #fff;
}

.is-inactive .number-spinner--primary .number-spinner__input {
    border-color: var(--color-primary);
}
.is-inactive .number-spinner--primary .number-spinner__btn {
    background: var(--color-primary);
}

.number-spinner__btn:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
    z-index: 1;
}
.number-spinner__btn:active:not(:disabled):after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
}

/* small */
.number-spinner.number-spinner--sm .number-spinner__input {
    height: calc(39rem / 16);
}
.number-spinner.number-spinner--sm .number-spinner__btn {
    width: calc(45rem / 16);
    padding: calc(6rem / 16) calc(5rem / 16);
}
/* xs for phones*/
.number-spinner.number-spinner--xs .number-spinner__input {
    height: calc(27rem / 16);
    @media screen and (min-width: 768px){
        height: calc(39rem / 16);
    }
}
.number-spinner.number-spinner--xs .number-spinner__btn {
    width: calc(27rem / 16);
    padding: calc(1rem / 16) calc(2rem / 16);
    @media screen and (min-width: 768px) {
        width: calc(45rem / 16);
        padding: calc(6rem / 16) calc(5rem / 16);
    }
}

.number-spinner.number-spinner--sm.number-spinner-cart .number-spinner__btn {
    width: calc(29rem/16);
}