.data-row {
    line-height: calc(22/18);
    max-width: calc(1005rem / 16);
    padding: calc(30rem / 16);
    margin: 0 auto;
    word-break: normal;

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.data-row__title {
    font-size: calc(18rem / 16);
    line-height: calc(36/25);

    @media screen and (min-width: 786px) {
        font-size: calc(25rem / 16);
    }
}
.data-row__data {
    position: relative;
    letter-spacing: calc(.3rem / 16);
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
        padding: calc(8rem / 16) 0;
        margin-right: calc(20rem / 16);
    }
}
.data-row__data:not(:first-child) {
    @media screen and (min-width: 768px) {
        padding-left: calc(25rem / 16);
        margin-left: calc(25rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding-left: calc(39rem / 16);
        margin-left: calc(39rem / 16);
    }
}
.data-row__data:not(:first-child):before {
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        left: 0;
        background: #fff;
        opacity: .3;
        width: calc(1rem / 16);
        top: calc(4rem / 16);
        bottom: calc(6rem / 16);
    }
}

.data-row--sm {
    max-width: calc(683rem / 16);
}