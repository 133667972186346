.img-slide {
    position: relative;
    box-shadow: 0 calc(20rem / 16) calc(50rem / 16) 0 rgba(0,0,0,0.2);
    overflow: hidden;
}
.img-slide:not(.img-slide--background):after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(43,43,42,0.4) 0%, rgba(43,43,42,0.1) 100%);
}
.img-slide--background {
    padding: calc(55rem / 16) 0;
    background-image: var(--img-src-xs) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 768px) {
        background-image: var(--img-src) !important;
        padding: calc(85rem / 16) 0;
    }
}
.img-slide--background:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(25,43,79,0.6) 0%, var(--color-secondary) 80%);
}

.img-slide__img {
    transition: transform 200ms ease-in-out;
}
.img-slide:hover .img-slide__img {
    transform: scale(1.02);
}

.img-slide__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    padding: calc(15rem / 16) calc(20rem / 16);
    color: #fff;
}
.img-slide__content__title {
    font-size: calc(26rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(45rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(60rem / 16);
    }
}

.img-slide--small .img-slide__content__title{
    @media screen and (min-width: 768px){
       font-size: calc(38rem/16);
    }
}

.img-slide__content__subtitle {
    font-size: calc(16rem / 16);
    line-height: calc(30/25);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(25rem / 16);
    }
}

.img-slide--small .img-slide__content__subtitle{
    @media screen and (min-width: 768px){
       font-size: calc(21rem/16);
    }
}