/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-underline {text-decoration: underline;}
.text-outline {
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: calc(1rem / 16);
    -webkit-text-stroke-color: currentColor;

    @media screen and (min-width: 768px) {
        -webkit-text-stroke-width: calc(2rem / 16);
    }
}
.text-line-through {text-decoration: line-through;}

/* Text Color Helper */
.text-primary-hover:hover, .text-primary-hover:focus {color: var(--color-primary);}
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-dark-grey {color: var(--color-dark-grey);}
.text-grey {color: var(--color-grey);}

/* Background Color Helper */
.bg-light-grey { background: var(--color-light-grey); color: var(--color-dark)}
.bg-pattern {
    background-image: url("/static/build/img/backgrounds/decorator.svg");
    background-size: cover;
    background-repeat: repeat-y;
}
.bg-hotels { background: var(--color-hotels); color: #fff}
.bg-white-transparent {background: rgba(255,255,255,.1);}

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.btn-no-styling:focus {
    outline: none;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}
.clickable {cursor: pointer;}

.content-box { border: calc(1rem / 16) solid calc(1rem / 16) solid var(--color-primary);}

.circle-item {
    width: calc(16rem / 16);
    height: calc(16rem / 16);
    border-radius: 50%;
    font-size: calc(9rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
}
.circle-item--absolute {
    position: absolute;
    right: calc(-10rem / 16);
    bottom: calc(-1rem / 16);
}
.circle-item--icon {
    font-size: calc(6rem / 16);
}

.figure {
    position: relative;
    margin-bottom: 0;
}
.figure__caption {
    font-size: calc(18rem / 16);
    line-height: calc(35/18);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(25,43,79,0) 0%, rgba(25,43,79,.7) 100%);
    padding: calc(10rem / 16) calc(5rem / 16);
}

.icon-rotate-22 {transform: rotate(22deg);}
.icon-rotate­45 {transform: rotate(45deg);}
.icon-rotate­68 {transform: rotate(68deg);}
.icon-rotate-90 {transform: rotate(90deg);}
.icon-rotate­112 {transform: rotate(112deg);}
.icon-rotate­135 {transform: rotate(135deg);}
.icon-rotate­158 {transform: rotate(158deg);}
.icon-rotate­180 {transform: rotate(180deg);}
.icon-rotate­202 {transform: rotate(202deg);}
.icon-rotate­225 {transform: rotate(225deg);}
.icon-rotate­248 {transform: rotate(248deg);}
.icon-rotate­270 {transform: rotate(270deg);}
.icon-rotate­292 {transform: rotate(292deg);}
.icon-rotate­315 {transform: rotate(315deg);}
.icon-rotate­338  {transform: rotate(338deg);}

.embed-responsive-527by138:before {
    padding-top:26.19%;
}
.embed-responsive-245by138:before {
    padding-top:56.33%;
}
.embed-responsive-527by138 img, .embed-responsive-245by138 img {
    object-fit:cover;
}

.w-40 {
    width:40%;
}
.w-md-40 {
    @media screen and (min-width: 768px) {
        width:40%;
    }
}

.w-xs-100{
    @media screen and (max-width: 767px){
       width: 100%;
    }
}