.slide-sm,
.slide.slide-sm {
    position: relative;
    margin-top: calc(30rem/16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(70rem/16);
        margin-bottom: calc(70rem/16);
    }
}
.slide {
    position: relative;
    margin-top: calc(60rem/16);
    margin-bottom: calc(60rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(100rem/16);
        margin-bottom: calc(100rem/16);
    }
}

/* slides with background color / image */
.slide.slide[class*='bg-'] {
    margin: 0;
    padding-top: calc(60rem / 16);
    padding-bottom: calc(60rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(100rem / 16);
        padding-bottom: calc(100rem / 16);
    }
}

/* full height slides */
.slide.slide--full-height,
.slide--full-height {
    @media screen and (min-width: 768px) {
        margin: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 0;
        padding-top: calc(90rem / 16);
        padding-bottom: calc(90rem / 16);
    }
}
.slide--full-height .slide__content-wrapper{
    @media screen and (min-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - calc(180rem / 16));
    }
}
.slide--full-height .slide__content {
    @media screen and (min-width: 768px) {
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}


.slide__decorator {
    position: absolute;
    top: calc(1rem / 16);
    left: 0;
    right: 0;
    transform: translateY(-100%);
    z-index: 1;
    max-width: 100%;

    &+.container{
        @media screen and (max-width: 767px){
            padding-top: 1.5rem;
        }
    }

    @media screen and (min-width: 768px) {
        max-height: calc(255rem / 16);
    }
}
.slide__decorator path {
    fill: var(--color-light-grey);
}


/* fix page layouts with same background colors */
.page-wrapper--light .slide.slide.bg-light-grey {
    padding: 0;
    margin-top: calc(60rem/16);
    margin-bottom: calc(60rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(150rem/16);
        margin-bottom: calc(150rem/16);
    }
}
.page-wrapper--light .slide.bg-light-grey.bg-pattern {
    background: none;
}
.page-wrapper:not(.page-wrapper--light) .slide.bg-secondary {
    padding: 0;
    margin-top: calc(60rem/16);
    margin-bottom: calc(60rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(150rem/16);
        margin-bottom: calc(150rem/16);
    }
}
.page-wrapper:not(.page-wrapper--light) .slide.bg-secondary.bg-pattern {
    background: none;
}