@media screen and (max-width: 767px) {
    .navbar {
        background: rgba(255,255,255, .8);
        height: calc(53rem / 16);
        transition: background 200ms ease-in-out;
    }
    .is-open.navbar {
        background: #fff;
    }

    .navbar__main {
        border-top: calc(1rem / 16) solid var(--color-light-grey);
        z-index: 1;
    }

    .navbar__main,
    .navbar-nav__subnav {
        position: fixed;
        left: 0;
        right: 0;
        top: calc(53rem / 16);
        height: calc(100vh - calc(53rem / 16));
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;

    }

    .is-open .navbar__main,
    .is-initial-open .navbar__main,
    .is-open > .navbar-nav__subnav,
    .is-initial-open > .navbar-nav__subnav {
        transform: translateX(0);
    }

    .navbar__main-container {
        padding: calc(40rem / 16) 0 calc(60rem / 16);
        height: calc(100vh - calc(53rem / 16));
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .navbar__main .nav-link {
        text-transform: uppercase;
        font-size: calc(20rem / 16);
        font-family: var(--font-default-bold);
        font-weight: var(--font-bold-weight);
    }
    .navbar-nav__subnav .nav-link {
        font-size: calc(18rem / 16);
        line-height: calc(20/18);
        text-transform: none;
        font-family: var(--font-default);
        font-weight: var(--font-default-weight);
    }
    .navbar-nav__subnav {
        top: 0;
        padding: calc(40rem / 16) 0;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }



    /* toggle button */
    .navbar-toggler {
        cursor: pointer;
        border: 0;
        padding: calc(10rem / 16);
    }
    .navbar-toggler__bar {
        display: block;
        width: calc(30rem / 16);
        height: calc(2rem / 16);
        border-radius: calc(2rem / 16);
        background: var(--color-secondary);
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
        transform-origin: left center;
        margin-left: auto;
        margin-right: auto;
    }
    .navbar-toggler__bar:not(:first-child) {
        margin-top: calc(8rem / 16);
    }
    .is-open .navbar-toggler__bar {
        transform: rotate(45deg) translateX(calc(5rem / 16)) translateY(calc(-3rem / 16));
    }
    .is-open .navbar-toggler__bar:nth-child(2) {
        transform: scaleX(0);
        opacity: 0;
    }
    .is-open .navbar-toggler__bar:last-child {
        transform: rotate(-45deg) translateX(calc(3rem / 16)) translateY(calc(5rem / 16));
    }


    /* navbar subnav title and back link */
    .navbar-nav__subnav__title {
        position: relative;
        letter-spacing: calc(2rem / 16);
        font-size: calc(20rem / 16);
    }

    .navbar-nav__subnav__back {
        font-size: calc(18rem / 16);
        margin: -10px;
        padding: 10px;
    }
    .navbar-nav__subnav__back__icon {
        font-size: calc(8rem / 16);
        margin-right: calc(5rem / 16);
    }

    /* navbar breadcrumb */
    .navbar-nav__subnav__breadcrumb {
        font-size: calc(12rem / 16);
    }
    .navbar-nav__subnav__breadcrumb__item {
        position: relative;
        line-height: 1;
        display: inline-block;
    }
    .navbar-nav__subnav__breadcrumb__item:not(:first-child) {
        margin-left: calc(8rem / 16);
        padding-left: calc(10rem / 16);
    }
    .navbar-nav__subnav__breadcrumb__item:not(:first-child):before {
        content: var(--icon-arrow-right);
        font-family: iconfont;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: calc(8rem / 16);
    }

    .navbar-nav__subnav__nav {
        margin-bottom: calc(20rem / 16);
    }

    .navbar__main__btn {
        white-space: nowrap;
    }

    .navbar__main__right {
        order: -1;
        width: 100%;
    }
}