.data-box {
    border: calc(1rem / 16) solid rgba(255,255,255,.3);
    font-size: calc(16rem / 16);
    line-height: calc(23/16);
    color: currentColor;
    transition: color 200ms ease-in-out, background 200ms ease-in-out;
    position: relative;
}
.page-wrapper--light .data-box {border: calc(1rem / 16) solid rgba(0,0,0,.3)}

.data-box + .data-box {
    margin-top: calc(28rem / 16);
}
.data-box__head {
    padding: calc(20rem / 16) calc(20rem / 16) 0 calc(15rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(20rem / 16) calc(25rem / 16) 0;
    }

    @media screen and (min-width: 1400px) {
        padding: calc(35rem / 16) calc(40rem / 16) 0;
    }
}

.data-box__content {
    position: relative;
    padding: calc(15rem / 16);
    transition: border-color 200ms ease-in-out;

    @media screen and (min-width: 1200px) {
        padding: calc(20rem / 16) calc(25rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(25rem / 16) calc(40rem / 16);
    }
}
.data-box__content--sm {
    @media screen and (min-width: 1200px) {
        padding: calc(15rem / 16) calc(25rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(20rem / 16) calc(40rem / 16);
    }
}

.data-box__content + .data-box__content {
    border-top: calc(1rem / 16) solid rgba(255,255,255,.3);
}

.data-box__content__data + .data-box__content__data {
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(18rem / 16);
    }
}
.data-box__content__data__label {
    font-size: calc(12rem / 16);
}
.data-box__content-wrapper {
    transition: opacity 200ms ease-in-out;
}
.is-inactive .data-box__content-wrapper {
    opacity: .55;
}
.is-inactive .data-box__content-wrapper:hover {
    opacity: .9;
}

.data-box__subtitle {
    font-size: calc(14rem / 16);
    line-height: calc(22/18);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(18rem / 16);
    }
}

.data-box__title {
    font-size: calc(18rem / 16);
    line-height: calc(30/25);
    color: var(--color-primary);
    transition: color 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
    }
}

.data-box__title--sm {
    font-size: calc(16rem / 16);
    line-height: calc(30/25);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}

.data-box__title--bold {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}

.data-box__btn {
    border: 0;
    width: calc(100% + calc(2rem / 16));
    margin: 0 calc(-1rem / 16) calc(-1rem / 16);
    font-size: calc(16rem / 16);
    line-height: calc(26/18);
    padding: calc(10rem / 16) 0;

    @media screen and (min-width: 1200px) {
        padding: calc(15rem / 16) 0;
        font-size: calc(18rem / 16);
    }
}

.data-box__icon {
    background-color: var(--color-primary);
    color: #fff;
    height: calc(24rem/16);
    width: calc(24rem/16);
    font-size: calc(10rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-box__btn:disabled {
    opacity: .3;
}
.data-box__btn.data-box__btn--sm {
    font-size: calc(14rem / 16);
}
.data-box__modifier {
    border: 0;
    box-shadow: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: calc(16rem / 16);
    line-height: 1;
    padding: calc(10rem / 16);
    z-index: 1;
    transition: background 200ms ease-in-out;
}
.data-box__modifier:not(.data-box__modifier--primary):hover {
    background: var(--color-dark-grey);
}
.data-box__modifier--primary {
    background: var(--color-primary);
    right: calc(-1rem / 16);
    top: calc(-1rem / 16);
    color: var(--color-secondary);
    border: calc(1rem / 16) solid rgba(255,255,255,.3);
    border-top-color: transparent;
    border-right-color: transparent;
}
.data-box__modifier--primary:hover,
.data-box__modifier--primary:focus {
    background: var(--color-primary-dark);
}
.data-box__modifier__icon {
    font-size: calc(17rem / 16);
    margin-right: calc(2rem / 16);
}
.data-box__modifier + .data-box__title,
.data-box__modifier + .data-box__content {
    margin-top: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(15rem / 16);
    }
}
.data-box__content__price {
    font-size: calc(18rem / 16);
    line-height: calc(28/25);

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
    }
}

.data-box--overview,
.data-box--preview {
    background: #fff;
    color: var(--color-secondary);
}
.data-box--overview .data-box__content,
.data-box--preview .data-box__content {
    border-color: var(--color-light-grey);
}
.data-box--overview .data-box__title,
.data-box--preview .data-box__title {
    color: var(--color-secondary);
}
.data-box__state {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(34rem / 16);
    height: calc(34rem / 16);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
.data-box--preview .data-box__state {
    opacity: 1;
}

/* small content */
.data-box--overview {
    font-size: calc(14rem / 16);
    line-height: calc(26/18);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(18rem / 16);
    }
}
/*
.data-box--overview .data-box__content + .data-box__content {
    @media screen and (min-width: 768px) {
        padding-top: calc(30rem / 16);
        margin-top: calc(30rem / 16);
    }
}*/


.data-box__switch {
    position: relative;
    display: inline-block;
    width: calc(50rem / 16);
    height: calc(24rem / 16);
    vertical-align: middle;
}
.data-box__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: calc(24rem / 16);
}
.data-box__switch-toggle:before {
    position: absolute;
    content: "";
    height: calc(16rem / 16);
    width: calc(16rem / 16);
    left: calc(4rem / 16);
    bottom: calc(4rem / 16);
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}
input:checked + .data-box__switch-toggle {
    background-color: var(--color-primary);
}
input:focus + .data-box__switch-toggle {
    box-shadow: 0 0 2px var(--color-primary);
}
input:checked + .data-box__switch-toggle:before {
    transform: translateX(26px);
}

/* bg light grey */
.bg-light-grey .data-box {
    border-color: var(--color-primary);
}

.data-box__add {
    line-height: 1;
}
.data-box__add-label {
    max-width: calc(165rem / 16);
}
.data-box__add small {
    font-size: calc(8rem / 16);
}

/* small item listing*/
.data-box__text-sm {
    font-size: calc(14rem / 16);
}