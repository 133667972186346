.metabar__nav {
    margin-bottom: 0;
}
.metabar__nav__item__link {
    padding: calc(16rem / 16) calc(49rem / 16) calc(13rem / 16);
    border-right: calc(1rem / 16) solid rgba(255,255,255,.5);
    background: rgba(43, 43, 42, 0.7);
    color: #fff;
    transition: color 200ms ease-in-out, background 200ms ease-in-out;
}
.metabar__nav__item__link:hover,
.metabar__nav__item__link.is-active {
    background: #fff;
    color: var(--color-dark);
}
.metabar__right {
    padding: 0 calc(15rem / 16);
    background: rgba(43, 43, 42, 0.7);
    color: #fff;

    @media screen and (min-width: 1400px) {
        padding: 0 calc(32rem / 16);
    }
}
.metabar__right__item{
    display: inline-block;
}
.metabar__right__item:not(:last-child) {
    margin-right: calc(15rem / 16);

    @media screen and (min-width: 1400px) {
        margin-right: calc(35rem / 16);
    }
}
.metabar__right__item__icon {
    font-size: calc(22rem / 16);
    vertical-align: -.1em;
    margin-right: calc(8rem / 16);
}