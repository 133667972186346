.video-js .vjs-big-play-button {
    border-radius: 50%;
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: #fff;
    background: transparent;
    border: calc(2rem / 16) solid var(--color-primary);
    transition: background 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        width: calc(126rem / 16);
        height: calc(126rem / 16);
    }
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(30rem / 16);
    height: auto;
    width: auto;

    @media screen and (min-width: 768px) {
        font-size: calc(56rem / 16);
    }
}
.video-js .vjs-big-play-button:focus, .video-js:hover .vjs-big-play-button {
    transition: background 200ms ease-in-out;
    background-color: rgba(255,255,255,.8);
}
.video-js .vjs-control-bar {
    background-color: rgba(0, 0, 0, 0.5);
}
.video-js .vjs-slider {
    background-color: rgba(255,255,255,.5);
}