
.btn {
    font-size: calc(16rem / 16);
    line-height: calc(26/18);
    padding: calc(12rem / 16) calc(20rem / 16) calc(11rem / 16);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        padding: calc(15rem / 16) calc(28rem / 16) calc(14rem / 16);
    }
}
.btn-outline-primary {
    color: currentColor;
}
.btn-outline-primary:hover {
    color: var(--color-primary-contrast);
}
.btn-light-grey {
    background: var(--color-light-grey);
    color: var(--color-secondary);
}
.btn-light-grey:hover {
    background: #fff;
    color: var(--color-secondary);
}
.btn-outline-transparent {
    border-color: rgba(0,0,0,.1);
    color: var(--color-secondary);
}
.btn-outline-transparent:hover {
    border-color: rgba(0,0,0,.5);
    color: var(--color-secondary);
}
.btn-facebook {
    background: #3b5999;
    color: #fff;
}
.btn-facebook:hover {
    background: #31497e;
    color: #fff;
}
.btn-google {
    background: #dd4b39;
    color: #fff;
}
.btn-google:hover {
    background: #b53d2f;
    color: #fff;
}

.btn.btn-download {
    padding: calc(12rem / 16) 0 calc(10rem / 16);
    font-weight: bold;
    background-color: var(--color-primary);
    color: var(--color-secondary) !important;
}

.btn-download:not(:last-child) {
    margin-bottom: calc(8rem/16);
}

.btn-download:hover,
.btn-download:focus,
.btn-download:active {
    background-color: var(--brand-primary-color-dark);
    text-decoration: none !important;
}

.btn-download a:hover {
    text-decoration: none;
}

.btn-download .icon {
    display: inline;
    color: var(--color-secondary);
    margin-left: calc(16rem/16);
}

.btn-bg-white{
    background-color: white;
    color: black;
}

.btn-bg-white:hover{
    background-color: var(--color-primary);
    color: black;
}

.btn-bg-white:focus{
    border: 1px black dotted;
}

.btn-back-link{
    font-size: 17px;
    @media screen and (min-width: 768px){
        font-size: 19px;
    }
}

.btn-back-link .icon{
    font-size: 12px;
    margin-right: 8px;
}

.btn-back-link:hover{
    text-decoration: underline;
}



/* sizes */
.btn.btn-badge {
    font-size: calc(16rem / 16);
    line-height: 1;
    padding: calc(7rem / 16) calc(10rem / 16) calc(6rem / 16);
    min-height: calc(35rem / 16);
}
.badge-row .btn-badge {
    position: relative;
    top: calc(-1rem / 16);
}
.btn-sm {
    font-size: calc(16rem / 16);
    line-height: calc(23/19);
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
    text-transform: uppercase;

    @media screen and (min-width: 786px) {
        font-size: calc(16rem / 16);
        padding: calc(17rem / 16) calc(20rem / 16) calc(15rem / 16);
    }
}

/* icon in button text */
.btn__icon {
    vertical-align: -0.15em;
    font-size: calc(18rem / 16);
    margin-right: calc(6rem / 16);
}
.btn-xs .btn__icon {
    font-size: calc(14rem / 16);
    margin-top: calc(2rem / 16);
}

/* icon only button */
.btn.icon-btn {
    padding: calc(2rem / 16);
    width: calc(48rem / 16);
    height: calc(48rem / 16);
    border-radius: 50%;
    border: calc(2rem / 16) solid var(--color-primary);
    position: relative;

    @media screen and (min-width: 768px) {
        width: calc(57rem / 16);
        height: calc(57rem / 16);
    }
}
.icon-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
    }
}

/*---------- doorpage mobile btn ----------*/
.btn-lang-mobile {
    border: none;
}
/*.btn-lang-mobile {
    @media screen and (max-width: 767px) {
        flex-grow: 2;
        text-align: right;
        border: none;
    }
}*/