.title-block {
    position: relative;
    margin-bottom: calc(25rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(90rem / 16);
    }
}

.title-block.title-block--sm {
    margin-bottom: calc(30rem / 16);
}

.title-block--main {
    padding-top: calc(40rem / 16);
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        margin-bottom: calc(60rem / 16);
        padding-top: calc(150rem / 16);
    }
}

.title-block--main-large {
    padding-bottom: calc(100rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(380rem / 16);
    }
}

.title-block--main-small {
    padding-top: 0.7rem;

    @media screen and (min-width: 768px){
        padding-top: calc(55rem / 16);
    }
}

.title-block--main-large-top {
    padding-top: 9rem;

@media screen and (min-width: 768px){
    padding-top: calc(55rem / 16);
}
}

.page-wrapper--light .title-block__title.text-white{
    color: var(--color-dark)!important;
}