.lightbox-item__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    border: calc(2rem / 16) solid var(--color-primary);
    color: #fff;
    border-radius: 50%;
    background: transparent;

    @media screen and (min-width: 768px) {
        width: calc(126rem / 16);
        height: calc(126rem / 16);
    }
}
.lightbox-item__btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(56rem / 16);
    }
}
.lg-outer .lg-video-cont {
    max-width: calc(1500rem / 16) !important;
}

/* lightgallery iframe */
.lg-has-iframe {
    max-width: calc(1500rem / 16) !important;
}
.lg-outer .lg-img-wrap {
    padding: calc(10rem / 16) calc(5rem / 16) calc(80rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(5rem / 16) calc(100rem / 16);
    }
}
.lg-outer .lg-video-object {
    max-height: calc(100vh - calc(50rem / 16));
}