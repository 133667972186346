.job-item {
    padding: calc(20rem / 16);
    margin: 0 auto;
    line-height: calc(26/22);
    max-width: calc(1097rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(25rem / 16) calc(20rem / 16);
        font-size: calc(18rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(22rem / 16);
    }
}
.job-item__title {
    font-size: calc(18rem / 16);
    line-height: calc(35/30);

    @media screen and (min-width: 786px) {
        font-size: calc(30rem / 16);
    }
}