.ribbon {
    position: absolute;
    z-index: 1;
    left: calc(-38rem / 16);
    top: calc(10rem / 16);
    height: calc(35rem / 16);
    transform: rotate(-45deg);
    font-size: calc(14rem / 16);
    line-height: calc(20/14);
    width: calc(120rem / 16);
    padding: 0 calc(20rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(60rem / 16);
        left: calc(-85rem / 16);
        top: calc(30rem / 16);
        width: calc(280rem / 16);
        font-size: calc(25rem / 16);
    }
}