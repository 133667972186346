html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    word-break: break-word;
    font-size: calc(16rem / 16);
    line-height: calc(30/16);

    @media screen and (min-width: 786px) {
        font-size: var(--font-size-default);
        line-height: calc(35/18);
    }
}
table {
    word-break: initial;
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
.font-default-bold,
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(34rem/16);
    line-height: calc(100/90);
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size: calc(70rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(90rem/16);
    }
}
h2, .h2 {
    font-size: calc(27rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size: calc(50rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(70rem/16);
    }
}
h3, .h3 {
    font-size: calc(20rem / 16);
    line-height: calc(45/40);
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size: calc(44rem / 16);
    }
}
h4, .h4 {
    font-size: calc(25rem / 16);
    line-height: calc(45/25);
    font-family: var(--font-default-bold);
    font-weight: var(--font-bold-weight);
}
h5, .h5 {
    font-size: calc(18rem/16);
}
h6, .h6 {
    font-size: 1em;
}

.fz10 { font-size: 10px; }
.fz12 { font-size: 12px; }
.fz16 { font-size: 16px; }