.teaser__badge {
    position: absolute;
    top: calc(15rem / 16);
    left: calc(-10rem / 16);
    background: #fff;
    color: var(--color-secondary);
    padding: calc(5rem / 16) calc(6rem / 16) calc(3rem / 16);
    font-size: calc(12rem / 16);
    z-index: 1;
    line-height: 1;
    box-shadow: 0 0 calc(7rem / 16) rgba(0,0,0, .1);

    @media screen and (min-width: 768px){
        top: calc(20rem / 16);
        font-size: calc(16rem / 16);
        padding: calc(12rem / 16) calc(12rem / 16) calc(7rem / 16);
    }

    @media screen and (min-width: 1400px) {
        left: calc(-20rem / 16);
    }
}
.teaser__badge__icon {
    font-size: calc(13rem / 16);
    vertical-align: -.1em;
    margin-right: calc(4rem / 16);
}

/* positions */
.teaser__badge--relative {
    position: relative;
    display: inline-block;
    top: 0;
    margin-top: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        top: 0;
        margin-top: calc(20rem / 16);
    }
}

.teaser__badge.teaser__badge--bottom {
    top: auto;
    bottom: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        bottom: calc(50rem / 16);
    }
}