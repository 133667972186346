.info-box {
    padding: calc(20rem / 16);
    box-shadow: 0 calc(20rem / 16) calc(70rem / 16) 0 rgba(0,0,0,0.3);
    pointer-events: auto; /* need in slider */
    background: var(--color-secondary-transparent);

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) calc(50rem / 16);
        width: calc(550rem / 16);
        max-width: 100%;
        margin-right: calc(-40rem / 16);
    }

    @media screen and (min-width: 1200px) {
        width: calc(630rem / 16);
        padding: calc(50rem / 16) calc(60rem / 16);
        margin-right: calc(-70rem / 16);
    }
}
.info-box__block + .info-box__block {
    margin-top: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(50rem / 16);
    }
}
.info-box__block__title {
    font-size: calc(20rem / 16);
    line-height: 1;
    margin-bottom: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
        margin-bottom: calc(28rem / 16);
    }
}
.info-box__block__suffix {
    font-size: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
    }
}