/* dropdown */
.navbar-nav__subnav {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background: #fff;
    z-index: 2;
    display: block;
}
.navbar-nav__subnav .nav-item > .nav-link {
    padding: calc(5rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(5rem / 16) 0;
        display: inline-block;
    }
}

.navbar-nav__subnav__nav {
    padding: calc(15rem / 16) calc(24rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0;
    }
}
.navbar-nav__subnav__nav--has-subitems {
    position: relative;

    @media screen and (min-width: 768px) {
        width: 50%;
    }
}
.navbar-nav__subnav__nav--has-subitems > .nav-item {
    @media screen and (min-width: 768px) {
        padding-right: calc(30rem / 16);
    }
}
.navbar-nav__subnav--level-1 {
    @media screen and (min-width: 768px) {
        top: 100%;
        border-top: calc(1rem / 16) solid var(--color-light-grey);
        opacity: 0;
        visibility: hidden;
        transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
        transition-delay: 250ms;
    }
}
.navbar-nav__subnav--level-1:hover,
.nav-item.is-open > .navbar-nav__subnav--level-1,
.nav-item:hover > .navbar-nav__subnav--level-1 {
    @media screen and (min-width: 768px) {
        opacity: 1;
        visibility: visible;
    }
}
.navbar-nav__subnav__nav--level-1 {
    @media screen and (min-width: 768px) {
        height: calc(100% - calc(60rem / 16));
        margin-top: calc(30rem / 16);
        margin-bottom: calc(30rem / 16);
        min-height: calc(350rem / 16);
        padding-left: calc(30rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding-left: calc(169rem / 16);
    }
}
.navbar-nav__subnav__nav--level-1 > .nav-item > .nav-link {
    @media screen and (min-width: 768px){
        text-transform: uppercase;
        letter-spacing: calc(1rem / 16);
        font-size: calc(20rem / 16);
        font-family: var(--font-default-bold);
        font-weight: var(--font-bold-weight);
        color: var(--color-secondary);
    }

    @media screen and (min-width: 1400px) {
        letter-spacing: calc(2rem / 16);
        font-size: calc(25rem / 16);
        line-height: calc(36/25);
    }
}
.navbar-nav__subnav__nav--level-1 > .nav-item + .nav-item {
    margin-top: calc(15rem / 16);

    @media screen and (min-width: 1400px) {
        margin-top: calc(32rem / 16);
    }
}

.navbar-nav__subnav--level-2 .nav-item .nav-link {
    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
        line-height: calc(35/18);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(18rem / 16);
    }
}
.navbar-nav__subnav--level-2 {
    width: 100%;

    @media screen and (min-width: 768px) {
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        left: 100%;
        right: auto;
        width: 98%;
        bottom: calc(30rem / 16);
        opacity: 0;
        transition: visibility 200ms ease-in-out, opacity 200ms ease-in-out;
        transition-delay: 250ms;
        visibility: hidden;
    }
}
.navbar-nav__subnav--level-2:hover,
.nav-item.is-open > .navbar-nav__subnav--level-2,
.nav-item:hover > .navbar-nav__subnav--level-2 {
    @media screen and (min-width: 768px) {
        opacity: 1;
        visibility: visible;
    }
}
.navbar-nav__subnav__nav--level-2 {
    width: 100%;

    @media screen and (min-width: 768px) {
        padding-left: calc(15rem / 16);
        border-left: calc(1rem / 16) solid rgba(43,43,42,0.1);
    }

    @media screen and (min-width: 1200px) {
        padding-left: calc(30rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding-left: calc(75rem / 16);
    }
}
.navbar-nav__subnav__nav--level-2 .nav-item + .nav-item {
    margin-top: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(3rem / 16);
    }

    @media screen and (min-width: 1200px) {
        margin-top: calc(6rem / 16);
    }
}

.nav-link--logo {
    transition: transform 200ms ease-in-out;
}
.nav-link--logo:hover {
    transform: translateX(calc(10rem / 16));
}
.nav-link__logo {
    max-width: calc(180rem / 16);
    max-height: calc(56rem / 16);
    width: auto;
    height: auto;

    @media screen and (min-width: 1400px) {
        max-width: calc(240rem / 16);
        max-height: calc(90rem / 16);
    }
}

/* small dropdown */
.navbar__main__dropdown {
    display: block;
    white-space: normal;
    position: fixed;
    top: calc(53rem / 16);
    right: calc(10rem / 16);
    left: 0;
    padding: calc(40rem / 16) calc(20rem / 16);
    font-size: calc(14rem / 16);
    box-shadow: 0 0 calc(6rem / 16) rgba(0,0,0,.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        width: calc(500rem / 16);
        left: auto;
        right: 0;
        position: absolute;
        top: 100%;
        padding: calc(60rem / 16) calc(30rem / 16) calc(60rem / 16) calc(60rem / 16);
        font-size: calc(16rem / 16);
        z-index: 2;
    }
}
.navbar__main__dropdown.is-active {
    opacity: 1;
    visibility: visible;
}
.navbar__main__dropdown__close{
    position: absolute;
    top: calc(10rem / 16);
    right: calc(10rem / 16);
    padding: calc(6rem / 16);
    font-size: calc(25rem / 16);
}
.navbar__main__dropdown__icon {
    font-size: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(43rem / 16);
    }
}
.navbar__main__dropdown__subtitle {
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.navbar__main__dropdown__title {
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
    }
}
.navbar__main__dropdown__link {
    position: absolute;
    bottom: calc(-10rem / 16);
    right: calc(-5rem / 16);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: calc(100rem / 16);
    height: calc(100rem / 16);
    font-size: calc(14rem / 16);
    padding: calc(10rem / 16);
    box-shadow: 0 calc(5rem / 16) calc(6rem / 16) rgba(0,0,0,.2);

    @media screen and (min-width: 768px) {
        width: calc(190rem / 16);
        height: calc(190rem / 16);
        font-size: calc(20rem / 16);
        padding: calc(30rem / 16);
        right: calc(-20rem / 16);
        bottom: calc(-20rem / 16);
    }
}