.card-teaser {
    display: flex;
    flex-direction: column;
    font-size: calc(14rem / 16);
    background: #fff;
    line-height: normal;
    color: var(--color-dark);
    height: 100%;
}

.card-teaser-description--description{
    max-height: 90px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.card-teaser__title {
    font-size: calc(22rem / 16);
    letter-spacing: calc(.2rem / 16);
}
.card-teaser__img {
    background-color: var(--color-light-gray);
    flex: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}
.card-teaser__img:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 45%;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.01) 18%,  rgba(255,255,255,0.45) 55%,
    rgba(255,255,255,0.85) 71%, rgba(255,255,255,1) 100%);
}
.card-teaser__img__show-gallery {
    position: absolute;
    top: calc(10rem / 16);
    right: calc(10rem / 16);
    color: #fff;
    font-size: calc(20rem / 16);
}
.card-teaser__label {
    flex: none;
    font-size: 84.61538%;
    padding: .25rem;
}
.card-teaser__price {
    font-size: calc(16rem / 16);
}

.btn.card-teaser__detail-btn {
    position: relative;
    height: calc(42rem / 16);
    width: calc(42rem / 16);
    border-radius: 50%;
    transition: transform 100ms ease-out;
    padding: 0 ;
    background-color: var(--color-light-grey);
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-teaser__detail-btn .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: var(--color-dark-gray);
    font-size: calc(14rem / 16);
}

.card-teaser--hotel{
    box-shadow: 0 0 15px 3px rgba(2, 4, 29, 0.25);
    background: transparent;
    font-size: 1rem;
    line-height: 1.4375;
    font-family: var(--font-default)
}

.card-teaser-description--hotel{
    background-color: var(--color-secondary);
    color: white;
    padding: 1rem 1.5rem 1.7rem 1.7rem;
    justify-content: space-between;
}

.card-teaser__title--hotel{
    color: var(--color-primary);
}

.hotel-card-teaser__arrow{
    color: var(--color-primary-light);
    font-size: 0.8rem;
}

.hotel-card-teaser__btn-text{
    font-size: calc(15rem/16);
    text-transform: initial;
    color: var(--color-primary);
}

.hotel-card-teaser__btn-text:hover{
    text-decoration: underline;
}

.card-teaser__img--hotel {
    background-color: var(--color-light-gray);
    flex: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}
.card-teaser__img--hotel:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 45%;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(25, 43, 79,0) 0%, rgba(25, 43, 79,0.06) 18%,  rgba(25, 43, 79,0.5) 55%,
    rgba(25, 43, 79,0.9) 71%, rgb(25, 43, 79) 100%);
}

.card-teaser__price--hotel{
    font-size: calc(18rem / 16);
    padding-bottom: calc(15rem/16);
    line-height: 2;
}

.hotel-card-teaser__buttons{
    justify-content: flex-start;
    display: flex;

    @media screen and (min-width: 768px) {
        justify-content: end;
    }
}

.hotel-card-teaser__buttons-link{
    height: 45px;
    padding-right: calc(15rem/16) !important;
    padding-left: calc(15rem/16) !important;

    @media screen and (min-width: 992px) {
        height: 55px;
    }
}