/*presse styles*/

.press-images__list, .press-releases__list{
    padding-top: 50px;
    padding-bottom: 40px;

    @media screen and (max-width: 767px){
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.pressrelease-teaser .col-md-8 {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .pressrelease-teaser__body{
        margin: 1.2rem 0;

        @media screen and (max-width: 767px){
            margin: 1rem 0;
        }
    }

    @media screen and (max-width: 767px){
        padding-top: 0.5rem;
    }
}

.press-detail__back {
    position: relative;
}
.press-detail__content .wysiwyg {
    text-align: center;
}
.press-detail__content {
    list-style: none;
    padding: 0;
}

.press-detail .title-block--main-small{
    padding-top: 2.5rem;
}