.state-circle {
    width: calc(70rem / 16);
    height: calc(70rem / 16);
    background: linear-gradient(209.4deg, var(--color-primary) 0%, #D9AB01 100%);
    color: var(--color-secondary);
    border-radius: 50%;
    position: relative;
    margin-bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(103rem / 16);
        height: calc(103rem / 16);
    }
}
.state-circle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(48rem / 16);
    }
}
.state-circle__state {
    width: calc(24rem / 16);
    height: calc(24rem / 16);
    border-radius: 50%;
    background: #fff;
    position: absolute;
    bottom: calc(-3rem / 16);
    right: calc(-3rem / 16);
    font-size: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(39rem / 16);
        height: calc(39rem / 16);
        bottom: calc(-15rem / 16);
        right: calc(-15rem / 16);
        font-size: calc(15rem / 16);
    }
}
.state-circle--bordered {
    border: calc(3rem / 16) solid #fff;

    @media screen and (min-width: 768px) {
        border: calc(5rem / 16) solid #fff;
    }
}
.state-circle--difficult {
    background: linear-gradient(214.51deg, #595956 0%, #2B2B2A 100%);
    color: #fff;
}
.state-circle--easy {
    background: linear-gradient(214.51deg, #27B1D7 0%, #07668C 100%);
    color: #fff;
}
.state-circle--medium {
    background: linear-gradient(214.51deg, #E96262 0%, #C22341 100%);
    color: #fff;
}
.state-circle--routes {
    background: linear-gradient(209.94deg, #F4CD3C 0%, #D38014 100%);
    color: #fff;
}
.state-circle--success {
    background: var(--color-success);
}

.state-circle.state-circle--sm {
    width: calc(24rem / 16);
    height: calc(24rem / 16);
    margin: 0;
}