.modal-body .form-box{
    width: 100%;
}

.card {
    border: none;
}

.acco-inquiry-form__success{
    border-bottom: solid 1px;
    border-color: var(--color-primary);
    display: inline-block;
}

.acco-inquiry-form__phone::placeholder{
    opacity: 0.5;
}

.acco-inquiry-form__heading{
    font-size: 2rem;
}