html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}

@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1696rem/16);
    width: 100%;
}
.page-wrapper {
    position: relative;
    background-color: var(--color-secondary);
}
.page-wrapper--light {
    color: var(--color-dark);
    background-color: var(--color-light-grey);
}
.page-wrapper:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-image: url("/static/build/img/backgrounds/decorator.png");
    background-size: 100% auto;
    background-repeat: repeat-y;
    opacity: .5;
}
.page-wrapper--light:before {
    opacity: .3;
}
.page-wrapper #main-content,
.page-wrapper .main-content {
    position: relative;
}

.container-xs {
    max-width: calc(760rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container-narrow {
    max-width: calc(1057rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container-md {
    max-width: calc(1338rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container-lg {
    max-width: calc(1860rem / 16);
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 767px){
    .container.container--no-padding-xs {
        padding: 0;
        overflow: hidden;
    }
}


@media screen and (min-width: 768px){
    .container.container--no-padding-md {
        padding: 0;
    }
}