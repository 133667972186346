.meta-list {
    margin-top: calc(14rem / 16);
    margin-bottom: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(24rem / 16);
        font-size: calc(20rem / 16);
    }
}
.meta-list__item {
    position: relative;
    display: block;

    @media screen and (min-width: 768px) {
        display: inline-block;
    }
}
.meta-list__item:not(:first-child) {
    @media screen and (min-width: 768px) {
        padding-left: calc(10rem / 16);
        margin-left: calc(10rem / 16);
    }
}
.meta-list__item:not(:first-child):before {
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        left: 0;
        top: calc(8rem / 16);
        bottom: calc(8rem / 16);
        width: calc(1rem / 16);
        background: currentColor;
        opacity: .8;
    }
}