.readmore {
    margin-bottom: calc(20rem / 16);
}

.readmore.is-open {
    display: inline;
    max-height: inherit;
}
.readmore__content{
    max-height: 160px;
    overflow: hidden;
}

.readmore.is-open .readmore__content{
    max-height: none;
    overflow: visible;
}
